export const validateFolderSlashSequence = (name: string): boolean => {
  let i = 0, sz = name.length;
  let lastChar = -2;
  for (i = 0; i < sz; i++) {
    const c = name.charCodeAt(i);
    if (c === '/'.charCodeAt(0)) {
      if (lastChar === i - 1) {
        return false;
      }

      lastChar = i;
    }
  }
  return true;
};
