import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { Panel } from '../common/Panel';
import { AtadenLogo, AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';


type DesktopAtadenLayoutProps = {
  children: ReactNode;
};


const Page = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${Colors.Gray100};
`;

const PageContainer = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 1920px;

    background-image: url("/blocks.jpg");
    background-size: cover;
    background-position: center;
`;

const ContainerCompany = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 36px;

    width: 100%;
    height: 100%;
`;

const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;
`;

const CompanyLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CompanyMojo = styled(AtdnText)`
    color: ${Colors.White};
    text-align: center;
    margin-bottom: ${spacing(8)};
`;

const MojoDescription = styled(AtdnText)`
    color: ${Colors.White};
    text-align: center;
`;

const ChildrenContainer = styled(Panel)`
    margin: 72px;
    justify-content: center;
    align-items: center;
`;

const LogoWrapper = styled.div`
    position: absolute;
    left: 36px;
    top: 36px;
`;

export const DesktopAtadenLayout = ({ children }: DesktopAtadenLayoutProps) => {

  return (
        <Page>
            <PageContainer>
                <ContainerCompany>
                    <LogoWrapper>
                        <AtadenLogo width="196px" height="45px" whiteMode/>
                    </LogoWrapper>
                    <CompanyLabelContainer>
                        <CompanyMojo scale={10}>
                            <FormattedMessage id="auth.login.company.title"/>
                        </CompanyMojo>
                        <MojoDescription scale={6}>
                            <FormattedMessage id="auth.login.company.description"/>
                        </MojoDescription>
                    </CompanyLabelContainer>
                </ContainerCompany>
                <ContainerForm>

                    <ChildrenContainer>
                        {children}
                    </ChildrenContainer>

                </ContainerForm>
            </PageContainer>
        </Page>
  );
};
