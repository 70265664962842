import { EmptyFolderMessage } from './EmptyFolderMessage';
import { ExplorerItemHeader } from './ExplorerItemHeader';
import { ExplorerItem, ExplorerItemSelectMode } from './ExplorerItem';
import { PageSelector } from './PageSelector';
import React, { useState } from 'react';
import { ExplorationItem } from '../domain/FileExploration';
import { LoadingFileComponent } from './LoadingFileComponent';

type BucketFileDisplayerProps = {
  bucketId: string;
  pageCount: number;
  pageNumber: number;
  pagePath: string;
  explorerItems: ExplorationItem[];
  isLoading: boolean;

  onPageChange: (expectedPageNumber: number, expectedPagePath: string) => void;
  onItemSelectionChanged: (selectedItems: string[]) => void;
  onRecovered: (item: ExplorationItem) => void;
};

export const BucketFileDisplayer = ({
  bucketId,
  pageCount,
  pageNumber,
  pagePath,
  explorerItems,
  isLoading,
  onPageChange,
  onItemSelectionChanged,
  onRecovered,
}: BucketFileDisplayerProps) => {

  const [selectedItems, updateSelectedItems] = useState<string[]>([]);

  const onFolderSelection = (folderPath: string) => {
    onPageChange(1, folderPath);
  };

  if (isLoading) {
    return (
            <LoadingFileComponent/>
    );
  }

  if (explorerItems.length === 0) {
    return (
            <EmptyFolderMessage/>
    );
  }

  const notifyItemSelectionChanged = (items: string[]) => {
    updateSelectedItems([...items]);
    onItemSelectionChanged(items);
  };

  const onSelectAll = () => {
    notifyItemSelectionChanged(explorerItems.map((item) => item.id));
  };

  const onUnselectAll = () => {
    notifyItemSelectionChanged([]);
  };

  const isItemSelected = (itemId: string): boolean => {
    return selectedItems.find((item) => item === itemId) !== undefined;
  };

  const computeSelectionMode = (): ExplorerItemSelectMode => {
    if (selectedItems.length === 0) {
      return 'NONE';
    }

    if (explorerItems.length === selectedItems.length) {
      return 'ALL';
    }

    return 'CUSTOM';
  };

  const onSelectionChange = (item: ExplorationItem, isSelected: boolean) => {
    if (isSelected) {
      selectedItems.push(item.id);
      notifyItemSelectionChanged(selectedItems);
      return;
    }

    notifyItemSelectionChanged(selectedItems.filter((itemId) => itemId !== item.id));
  };

  return (
        <>
            <ExplorerItemHeader selectMode={computeSelectionMode()} onSelectAll={onSelectAll}
                                onUnselectAll={onUnselectAll}/>
            {explorerItems.map((item) => (
                <ExplorerItem key={item.id} bucketId={bucketId} item={item} isSelected={isItemSelected(item.id)}
                              onFolderClick={onFolderSelection}
                              onSelectionChange={(isSelected) => onSelectionChange(item, isSelected)}
                              onRecovered={() => onRecovered(item)}/>
            ))}

            <PageSelector pageCount={pageCount} currentPage={pageNumber}
                          onPageSelection={(expectedPageNumber: number) => onPageChange(expectedPageNumber, pagePath)}/>
        </>
  );

};
