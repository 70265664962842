import { IntlShape } from 'react-intl';

const translate = (value: number, intl: IntlShape, translationKey: string): string => {
  return intl.formatMessage({ id: translationKey }, { value: +value.toFixed(2) });
};

export const byteTranslation = (bytes: number, intl: IntlShape): string => {

  if (bytes < 1024) {
    return translate(bytes, intl, 'common.byte.byte');
  }

  if (bytes < 1024 * 1024) {
    return translate(bytes / 1024, intl, 'common.byte.kilo');
  }

  if (bytes < 1024 * 1024 * 1024) {
    return translate(bytes / (1024 * 1024), intl, 'common.byte.mega');
  }

  if (bytes < 1024 * 1024 * 1024 * 1024) {
    return translate(bytes / (1024 * 1024 * 1024), intl, 'common.byte.giga');
  }

  if (bytes < 1024 * 1024 * 1024 * 1024 * 1024) {
    return translate(bytes / (1024 * 1024 * 1024 * 1024), intl, 'common.byte.tera');
  }

  return '######';
};
