import { DeleteItemsButton } from './DeleteItemsButton';
import { FileUploadButton } from './FileUploadButton';
import { CreateFolderButton } from './CreateFolderButton';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '@ataden/design-system';
import { ExplorationItem } from '../domain/FileExploration';
import { BucketDetails } from '../domain/Bucket';

type BucketToolBarProps = {
  bucket: BucketDetails;
  pagePath: string;

  selectedItems: ExplorationItem[];

  onFileCreated: (fileId: string) => void;
  onFolderCreated: (folderPath: string) => void;
  onItemDeleted: () => void;
};

const ToolBar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    margin-bottom: ${spacing(5)};
    gap: ${spacing(2)};
`;

export const BucketToolBar = ({
  bucket,
  pagePath,
  selectedItems,
  onFileCreated,
  onFolderCreated,
  onItemDeleted,
}: BucketToolBarProps) => {

  const nothing = () => {

  };

  if (selectedItems.length === 0) {
    return (
            <ToolBar>
                <FileUploadButton bucketId={bucket.id} path={pagePath} canUpload={true}
                                  onFileUploading={nothing} onFileUploaded={onFileCreated}/>
                <CreateFolderButton bucketId={bucket.id} path={pagePath} onFolderCreated={onFolderCreated}/>
            </ToolBar>
    );

  }

  return (
        <ToolBar>
            <DeleteItemsButton bucket={bucket} selectedItems={selectedItems} onItemsDeleted={onItemDeleted}/>
        </ToolBar>
  );
};
