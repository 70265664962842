import React from 'react';
import styled from 'styled-components';
import { BucketDetails } from '../../domain/Bucket';
import { UpdateBucketNameOption } from './UpdateBucketNameOption';
import { UpdateBucketRetentionDayOption } from './UpdateBucketRetentionDayOption';
import { UpdateBucketShowDeletionOption } from './UpdateBucketShowDeletionOption';
import { DeleteBucketOption } from './DeleteBucketOption';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';

type BucketOptionsTabProps = {
  bucket: BucketDetails;
  onBucketUpdated: (bucket: BucketDetails) => void;
};


const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
`;

const DangerTitle = styled(AtdnText)`
  margin: ${spacing(8)} 0;
  width: 100%;
  border-bottom: 1px solid ${Colors.Red500};
`;

export const BucketOptionsTab = ({ bucket, onBucketUpdated }: BucketOptionsTabProps) => {

  return (

        <TabContainer>
            <UpdateBucketNameOption bucket={bucket} onBucketUpdated={onBucketUpdated}/>
            <UpdateBucketRetentionDayOption bucket={bucket} onBucketUpdated={onBucketUpdated}/>
            <UpdateBucketShowDeletionOption bucket={bucket} onBucketUpdated={onBucketUpdated}/>

            <DangerTitle scale={4} type="important" color={Colors.Red900}>
                <FormattedMessage id="bucket.settings.danger-zone"/>
            </DangerTitle>

            <DeleteBucketOption bucket={bucket}/>
        </TabContainer>


  );
};
