import { useGetApiKeys } from '../repositories/ApiKeyRepository.hook';
import React, { useEffect, useState } from 'react';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { ApiKey } from '../domain/ApiKey';
import { ApiKeyCreationModal } from '../components/ApiKeyCreationModal';
import styled from 'styled-components';
import { ApiKeyTable } from '../components/ApiKeyTable';
import { AtdnButton, AtdnText, Colors, Loader } from '@ataden/design-system';
import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { FormattedMessage } from 'react-intl';

const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;

    padding: 0 24px 16px 8px;
`;

export const ApiKeyPage = () => {

  const projectId = useGetProjectIdFromPath();
  const getApiKeys = useGetApiKeys();

  const [apiKeys, updateApiKeys] = useState<ApiKey[]>();
  const [modalVisibility, updateModalVisibility] = useState<boolean>();

  useEffect(() => {
    getApiKeys(projectId)
      .then(updateApiKeys)
      .catch(() => {
        // TODO: handle errors
      });
  }, [projectId]);

  if (apiKeys === undefined) {
    return <Loader scale={6} secondaryColor={Colors.Brown700}/>;
  }

  const onModal = () => {
    updateModalVisibility(true);
  };

  const apiKeyDeleted = (apiKey: ApiKey) => {
    updateApiKeys(apiKeys.filter((key) => key.accessId !== apiKey.accessId));
  };

  return (
        <PageLayout>
            <PageHeader>
                <AtdnText scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="keys.title"/>
                </AtdnText>
                <PageDescription>
                    <FormattedMessage id="keys.description"/>
                </PageDescription>
            </PageHeader>


            <ActionButtons>
                <AtdnButton onClick={onModal}>
                    <FormattedMessage id="keys.create.cta"/>
                </AtdnButton>
            </ActionButtons>

            {apiKeys && (
                <ApiKeyTable apiKeys={apiKeys} onApiKeyDeleted={apiKeyDeleted}/>
            )}

            {modalVisibility && (
                <ApiKeyCreationModal onClosed={() => updateModalVisibility(false)}/>
            )}
        </PageLayout>
  );
};
