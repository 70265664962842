import { atadenAxiosInstance, Page, RestPage } from '../../common';
import {
  useProtectedDelete,
  useProtectedGet,
  useProtectedPatch,
  useProtectedPost,
} from '../../common/ProtectedRestCall.hook';
import { Bucket, BucketDetails, BucketToUpdate } from '../domain/Bucket';

type RestBucketCreated = {
  bucketId: string;
};


type RestBucket = {
  id: string;
  name: string;
  size: number;
  createdDate: number;
};


type RestBucketDetails = RestBucket & {
  sizeUploaded: number;
  retentionDay: number;
  showDeletion: boolean;
};


export const useCreateBucket = (businessId: string) => {
  const protectedPost = useProtectedPost<RestBucketCreated, string>(atadenAxiosInstance, (response) => response.bucketId);

  return (businessName: string): Promise<string> => {
    return new Promise<string>((success, failure) => {

      protectedPost('/api/v1/businesses/' + businessId + '/buckets', { name: businessName, dns: businessName })
        .then(bucketId => {
          success(bucketId);
        })
        .catch(failure);
    });
  };
};

const convertRestBucketToDomain = (bucket: RestBucket): Bucket => {
  return {
    ...bucket,
  };
};

const convertRestBucketDetailsToDomain = (bucket: RestBucketDetails): BucketDetails => {
  return {
    ...bucket,
  };
};


const convertRestPageBucketToDomain = (rest: RestPage<RestBucket>): Page<Bucket> => {
  return {
    ...rest,
    items: rest.items.map(convertRestBucketToDomain),
  };
};


export const useFetchBuckets = () => {
  const protectedGet = useProtectedGet<RestPage<RestBucket>, Page<Bucket>>(atadenAxiosInstance, convertRestPageBucketToDomain);

  return (businessId: string): Promise<Page<Bucket>> => {
    return new Promise<Page<Bucket>>((success, failure) => {

      protectedGet('/api/v1/businesses/' + businessId + '/buckets')
        .then(success)
        .catch(failure);
    });
  };
};

export const useGetBucket = () => {
  const protectedGet = useProtectedGet<RestBucketDetails, BucketDetails>(atadenAxiosInstance, convertRestBucketDetailsToDomain);

  return (bucketId: string): Promise<BucketDetails> => {
    return new Promise<BucketDetails>((success, failure) => {

      protectedGet('/api/v1/buckets/' + bucketId)
        .then(success)
        .catch(failure);
    });
  };
};


export const useUpdateBucket = () => {
  const protectedPatch = useProtectedPatch<void, void>(atadenAxiosInstance, () => {
  });

  return (bucketId: string, bucketToUpdate: BucketToUpdate): Promise<void> => {
    return new Promise<void>((success, failure) => {

      protectedPatch('/api/v1/buckets/' + bucketId, { ...bucketToUpdate })
        .then(success)
        .catch(failure);
    });
  };
};


export const useDeleteBucket = () => {
  const protectedDelete = useProtectedDelete(atadenAxiosInstance);

  return (bucketId: string): Promise<void> => {
    return new Promise<void>((success, failure) => {

      protectedDelete('/api/v1/buckets/' + bucketId)
        .then(success)
        .catch(failure);
    });
  };
};
