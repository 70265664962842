import React, { ReactElement, useState } from 'react';
import { BannerNavigationMenu } from './BannerNavigationMenu';
import { VerticalNavigationMenu } from './VerticalNavigationMenu';
import styled from 'styled-components';

type NavigationMenuProps = {
  children: ReactElement;
};

const Page = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

export const NavigationMenu = ({ children }: NavigationMenuProps) => {

  const [extendMenu, updateMenu] = useState<boolean>(false);

  const onMenuExpansion = () => {
    updateMenu(!extendMenu);
  };

  return (
        <>
            <BannerNavigationMenu onMenuExpansion={onMenuExpansion}/>
            <Page>
                {extendMenu && <VerticalNavigationMenu/>}
                {children}
            </Page>
        </>
  );
};
