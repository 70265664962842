import { BucketFileExplorer } from './BucketFileExplorer';
import React from 'react';
import styled from 'styled-components';
import { BucketDetails } from '../domain/Bucket';

type BucketFileTabProps = {
  projectId: string;
  bucket: BucketDetails;
};


const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const BucketFileTab = ({ projectId, bucket }: BucketFileTabProps) => {

  return (
 
        <TabContainer>
            <BucketFileExplorer projectId={projectId} bucket={bucket}/>
        </TabContainer>


  );
};
