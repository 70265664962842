import { AtdnButton, AtdnModal, AtdnText, Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import React from 'react';
import { StripeCardRegistration } from './StripeCardRegistration';
import { PaymentProvider } from '../../config/PaymentProvider';
import { FormattedMessage } from 'react-intl';

type CardPaymentMethodCreationModalProps = {
  onClosed: () => void;
};


const Title = styled(AtdnText)`
  color: ${Colors.Brown700};
  white-space: break-spaces;
  margin-bottom: 24px;
`;

const Label = styled(AtdnText)`
  color: ${Colors.Brown700};
  margin-bottom: 8px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing(6)};
  width: 100%;
  justify-content: center;
`;

const CardModal = styled(AtdnModal)`
  overflow-y: auto;
`;

export const CardPaymentMethodCreationModal = ({ onClosed }: CardPaymentMethodCreationModalProps) => {

  return (
        <PaymentProvider>

            <CardModal onCancel={onClosed}>
                <Title scale={5} type="important">
                    <FormattedMessage id="billing.cards.create.modal.title"/>
                </Title>
                <Label scale={3}>
                    <FormattedMessage id="billing.cards.create.modal.label"/>
                </Label>

                <StripeCardRegistration/>

                <ActionContainer>
                    <AtdnButton onClick={onClosed}>
                        <FormattedMessage id="billing.cards.create.modal.cancel"/>
                    </AtdnButton>
                </ActionContainer>
            </CardModal>
        </PaymentProvider>
  );
};
