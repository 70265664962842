import { ExplorerItemHeader } from './ExplorerItemHeader';
import { Loader, spacing } from '@ataden/design-system';
import React from 'react';
import styled from 'styled-components';

const LoadingFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  padding-top: ${spacing(8)};
`;

export const LoadingFileComponent = () => {
  const nothing = () => {

  };

  return (
        <>
            <ExplorerItemHeader selectMode='NONE' onSelectAll={nothing} onUnselectAll={nothing}/>
            <LoadingFileContainer>
                <Loader scale={5}/>
            </LoadingFileContainer>
        </>
  );
};
