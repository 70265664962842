import { ExplorationItem, FileExplorationItem, FolderExplorationItem } from '../domain/FileExploration';
import { ExplorerFolderItem } from './ExplorerFolderItem';
import { ExplorerFileItem } from './ExplorerFileItem';

type ExplorerItemProps = {
  bucketId: string;
  item: ExplorationItem;
  isSelected: boolean;

  onFolderClick: (path: string) => void;
  onSelectionChange: (isSelected: boolean) => void;
  onRecovered: () => void;
};

export type ExplorerItemSelectMode = 'ALL' | 'NONE' | 'CUSTOM';

export const ExplorerItem = ({
  bucketId,
  item,
  isSelected,
  onFolderClick,
  onSelectionChange,
  onRecovered,
}: ExplorerItemProps) => {

  switch (item.type) {
    case 'FILE':
      return <ExplorerFileItem bucketId={bucketId}
                                     item={item as FileExplorationItem}
                                     isSelected={isSelected}
                                     onSelectionChange={onSelectionChange}
                                     onRecovered={onRecovered}/>;
    case 'FOLDER':
      return <ExplorerFolderItem bucketId={bucketId}
                                       item={item as FolderExplorationItem}
                                       isSelected={isSelected}
                                       onFolderClick={onFolderClick}
                                       onSelectionChange={onSelectionChange}
                                       onRecovered={onRecovered}/>;
  }
};
