import { useMemo } from 'react';
import styled from 'styled-components';
import { AtdnText, Colors, HouseIcon, spacing } from '@ataden/design-system';

type BreadcrumbProps = {
  bucketName: string;
  path: string;

  onPathSelection: (path: string) => void;
};

type PathItem = {
  name: string;
  path: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    min-height: 20px;
`;

const Separator = styled.div`
    padding: 0 ${spacing(2)};
`;


const Folder = styled.div`
    cursor: pointer;
`;

const CurrentFolder = styled.div`

`;

const FolderContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const HomeIconWrapper = styled.div`
    cursor: pointer;
    width: 16px;
`;

const splitPath = (bucketName: string, pathToSplit: string): PathItem[] => {
  const result: PathItem[] = [];

  let prevPath = '';
  pathToSplit.split('/').map((item) => item.trim()).filter((item) => item.length > 0).forEach((path) => {
    result.push({
      name: path,
      path: prevPath + '/' + path,
    });

    prevPath = prevPath + '/' + path;
  });

  return result;
};

export const Breadcrumb = ({ bucketName, path, onPathSelection }: BreadcrumbProps) => {

  const pathItems = useMemo(() => {
    return splitPath(bucketName, path);
  }, [path]);
 
  const onHomeSelected = () => {
    onPathSelection('/');
  };

  const onPathSelected = (selectedPath: string) => {
    onPathSelection(selectedPath + '/');
  };

  return (
        <Container>
            {pathItems.length > 0 && (
                <HomeIconWrapper onClick={onHomeSelected}>
                    <HouseIcon/>
                </HomeIconWrapper>
            )}

            {pathItems.map((item, idx) => (
                <FolderContainer key={'_' + idx}>
                    <Separator>/</Separator>

                    {idx === pathItems.length - 1 ?
                        <CurrentFolder onClick={() => onPathSelected(item.path)}>
                            <AtdnText
                                scale={3}>{item.name}
                            </AtdnText>
                        </CurrentFolder>
                      :
                        <Folder onClick={() => onPathSelected(item.path)}>
                            <AtdnText
                                scale={3} type="important" color={Colors.Brown700}>{item.name}
                            </AtdnText>
                        </Folder>
                    }
                </FolderContainer>
            ))}


        </Container>
  );
};
