import React, { useMemo, useState } from 'react';
import { AtadenLayout } from '../../layout/AtadenLayout';
import styled from 'styled-components';
import { useCreateBusiness } from '../repositories/BusinessRepository.hook';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../AuthenticatedRouter';
import { ServerExceptionReader } from '../../common/ServerException';
import { AtdnButton, AtdnInput, AtdnText, Colors, InputErrorText, Loader } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';

const Title = styled(AtdnText)`
    text-align: left;
    margin-bottom: 24px;
`;

const Paragraph = styled(AtdnText)`
    text-align: left;
    margin-bottom: 36px;
`;

const Content = styled.div`

`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;


export const CreateBusinessPage = () => {

  const [businessName, updateBusinessName] = useState<string>('');
  const [isCreatingBusiness, updateCreatingBusiness] = useState<boolean>(false);
  const [forbiddenBusinessName, updateForbiddenBusinessName] = useState<string>();

  const intl = useIntl();
  const navigate = useNavigate();
  const createBusiness = useCreateBusiness();

  const onCreate = useMemo(() => {
    return () => {
      updateCreatingBusiness(true);
      createBusiness(businessName.trim())
        .then(() => {
          navigate(RoutePath.dashboard);
        })
        .catch(err => {
          if (ServerExceptionReader.isBusinessNameAlreadyExistsException(err)) {
            updateForbiddenBusinessName(businessName.trim());
          }

          updateCreatingBusiness(false);
        });
    };
  }, [businessName, createBusiness, navigate]);

  const createButton = useMemo(() => {
    if (isCreatingBusiness) {
      return (<AtdnButton size='big' type="Success" disabled>
                <FormattedMessage id="onboarding.business.cta.creating"/>
                <Loader scale={2}/>
            </AtdnButton>);
    }

    const businessNameTrimmed = businessName.trim();
    const canCreate = businessNameTrimmed.length > 0 && businessNameTrimmed !== forbiddenBusinessName;

    return (<AtdnButton size='big' type="Success" onClick={onCreate} disabled={!canCreate}>
            <FormattedMessage id="onboarding.business.cta"/>
        </AtdnButton>);

  }, [isCreatingBusiness, businessName, onCreate]);

  return (
        <AtadenLayout withMobileCompanyDescription={false}>
            <Title type="important" scale={7} color={Colors.Brown700}>
                <FormattedMessage id="onboarding.business.title"/>
            </Title>

            <Content>
                <Paragraph scale={5}>
                    <FormattedMessage id="onboarding.business.description"/>
                </Paragraph>
                <InputWrapper>
                    <AtdnInput maxLength={200} label={intl.formatMessage({ id: 'onboarding.business.input' })}
                               value={businessName} required
                               onChange={(event) => updateBusinessName(event.target.value)}/>
                    {(businessName.trim() === forbiddenBusinessName) && (
                        <InputErrorText>
                            <FormattedMessage id="onboarding.business.input.error"/>
                        </InputErrorText>)}
                </InputWrapper>
                <ButtonWrapper>
                    {createButton}
                </ButtonWrapper>

            </Content>
        </AtadenLayout>
  );
};
