import { AtdnText, Colors } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { PaymentMethodComponent } from './components/PaymentMethodComponent';
import { BillsComponent } from './components/BillsComponent';

export const BillingPage = () => {
  return (
        <PageLayout>
            <PageHeader>
                <AtdnText scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="billing.title"/>
                </AtdnText>
                <PageDescription>
                    <FormattedMessage id="billing.description"/>
                </PageDescription>
            </PageHeader>

            <BillsComponent/>
            <PaymentMethodComponent/>
        </PageLayout>
  );
};
