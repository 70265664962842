import {
  AtdnCheckbox,
  AtdnCheckBoxState,
  AtdnText,
  Colors,
  FileIcon,
  RefreshIcon,
  spacing,
  Tooltip,
} from '@ataden/design-system';
import { FileExplorationItem } from '../domain/FileExploration';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { dateNumberToDateString, timeNumberToTimeString } from '../../common/DateUtils';
import { byteTranslation } from '../../common/ByteTranslation';
import { ExplorerItemContainer } from './ExplorerItem.style';
import { useExploreRecoverItems } from '../repository/ExploreFileRepository.hook';


type ExplorerDeletedFileItemProps = {
  bucketId: string;
  item: FileExplorationItem;
  isSelected: boolean;
  onSelectionChange: (selected: boolean) => void;
  onRecovered: () => void;
};


const ItemValue = styled(AtdnText)`
    display: flex;
    align-items: center;
`;

const ItemLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing(2)};
`;

const ItemName = styled(AtdnText)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Tools = styled.div`

`;

const IconWrapper = styled.div`
    cursor: pointer;
`;

const CustomFileIcon = styled(FileIcon)`
    min-width: 24px;
    width: 24px;
    max-width: 24px;
`;


export const ExplorerDeletedFileItem = ({
  bucketId,
  item,
  isSelected,
  onSelectionChange,
  onRecovered,
}: ExplorerDeletedFileItemProps) => {
  const intl = useIntl();
  const exploreRecoverItems = useExploreRecoverItems();

  const date = dateNumberToDateString(item.createdDate, intl.formatMessage({ id: 'common.date' }));
  const eraseDate = dateNumberToDateString(item.eraseDateAt, intl.formatMessage({ id: 'common.date' }));
  const eraseTime = timeNumberToTimeString(item.eraseDateAt, intl.formatMessage({ id: 'common.time.short' }));
  const size = item.size ? byteTranslation(item.size, intl) : '-';

  const tooltips = intl.formatMessage({ id: 'bucket.files.item.tooltips.erased-at' }, {
    eraseDate: eraseDate,
    eraseTime: eraseTime,
  });

  const onFileSelection = (checked: AtdnCheckBoxState) => {
    onSelectionChange(checked === 'CHECKED');
  };

  const onRecover = () => {
    exploreRecoverItems(bucketId, [], [item.id])
      .then(onRecovered)
      .catch(() => {
        // TODO: handle error
      });
  };

  return (
        <ExplorerItemContainer>
            <AtdnCheckbox state={isSelected ? 'CHECKED' : 'UNCHECKED'} onChange={onFileSelection}/>

            <ItemLabelContainer>
                <CustomFileIcon width="24px" color={Colors.Gray500}/>
                <Tooltip text={tooltips}
                         delayInMs={500}
                         position="bottom">
                    <ItemName color={Colors.Gray500}>
                        {item.name} (<FormattedMessage id="bucket.files.folder.deleted"/>)
                    </ItemName>
                </Tooltip>
            </ItemLabelContainer>
            <ItemValue color={Colors.Gray500}>{size}</ItemValue>
            <ItemValue color={Colors.Gray500}>{date}</ItemValue>
            <Tools>
                <Tooltip text={intl.formatMessage({ id: 'bucket.files.item.tooltips.icon' })}
                         delayInMs={500}
                         position="left">
                    <IconWrapper onClick={onRecover}>
                        <RefreshIcon width="24px"/>
                    </IconWrapper>
                </Tooltip>
            </Tools>

        </ExplorerItemContainer>
  );
};
