import React, { useState } from 'react';
import { ApiKey } from '../domain/ApiKey';
import styled from 'styled-components';
import { useRevokeApiKey } from '../repositories/ApiKeyRepository.hook';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { AtdnButton, AtdnInput, AtdnModal, Colors, Loader, spacing } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';

type ApiKeyDeletionModalProps = {
  apiKey: ApiKey;

  onClosed: () => void;
  onDeleted: () => void;
};

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 90%;
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 90%;
    color: ${Colors.Brown700};
    margin-bottom: 16px;
    letter-spacing: -0.04em;
`;

const Warning = styled.div`
    font-size: 16px;
    padding: 16px;
    font-weight: 500;
    line-height: 90%;
    background-color: ${Colors.Yellow100};
    color: ${Colors.Yellow900};
    border-radius: 8px;
    margin-bottom: 16px;
`;

const DeleteInputWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: ${spacing(5)};
`;

const DeleteInput = styled(AtdnInput)`
    margin-bottom: 48px;
`;

export const ApiKeyDeletionModal = ({ apiKey, onClosed, onDeleted }: ApiKeyDeletionModalProps) => {
  const intl = useIntl();

  const projectId = useGetProjectIdFromPath();

  const [confirmValue, updateConfirmValue] = useState<string>('');
  const [isDeleting, updateDeleting] = useState<boolean>(false);

  const revokeApiKey = useRevokeApiKey();

  const onDelete = () => {
    updateDeleting(true);
    revokeApiKey(projectId, apiKey.accessId)
      .then(onDeleted)
      .catch(() => {
        // TODO: manage error
        onClosed();
      });
  };

  const keywork = intl.formatMessage({ id: 'keys.revoke.modal.revoke-key' }).trim().toLowerCase();

  return <AtdnModal onCancel={onClosed}>
        <div>
            <Title>
                <FormattedMessage id="keys.revoke.modal.title"/>
            </Title>
            <Label>
                <FormattedMessage id="keys.revoke.modal.description" values={{ apiKey: apiKey.accessId }}/>
            </Label>
            <Warning>
                <FormattedMessage id="keys.revoke.modal.warning"/>
            </Warning>
            <DeleteInput label={intl.formatMessage({ id: 'keys.revoke.modal.label' })}
                         value={confirmValue}
                         onChange={(event) => updateConfirmValue(event.target.value)}
                         required/>

            <DeleteInputWrapper>
                <AtdnButton onClick={onClosed}>
                    <FormattedMessage id="keys.revoke.modal.cancel"/>
                </AtdnButton>
                {isDeleting ? (
                    <AtdnButton type='Danger' onClick={() => {
                    }} disabled>
                        <FormattedMessage id="keys.revoke.modal.processing"/>
                        <Loader scale={1}/>
                    </AtdnButton>
                ) : (
                    <AtdnButton type='Danger' onClick={onDelete}
                                disabled={confirmValue.trim().toLowerCase() !== keywork}>
                        <FormattedMessage id="keys.revoke.modal.revoke"/>
                    </AtdnButton>
                )}
            </DeleteInputWrapper>
        </div>
    </AtdnModal>;
};
