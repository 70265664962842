import React, { useState } from 'react';
import styled from 'styled-components';
import { AtdnButton, AtdnInput, AtdnModal, Colors, InputErrorText, spacing } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCreateFolder } from '../repository/FolderRepository.hook';
import { validateFolderSlashSequence } from '../domain/FolderValidator';

type CreateFolderModalProps = {
  bucketId: string;
  path: string;

  onFolderCreate: (folderPath: string) => void;
  onClosed: () => void;
};

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 90%;
  text-transform: uppercase;
  color: ${Colors.Brown700};
  white-space: break-spaces;
  margin-bottom: 24px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 90%;
  color: ${Colors.Brown700};
  margin-bottom: ${spacing(9)};
  letter-spacing: -0.04em;
  max-width: 360px;
  overflow-wrap: break-word;
`;


const BtnLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${spacing(4)};
`;

const Input = styled(AtdnInput)`
  min-width: 360px;
  margin-bottom: ${spacing(2)};
`;

const InputError = styled(InputErrorText)`
  margin-bottom: ${spacing(6)};
`;

const FOLDER_PATH_SLASH_SEQUENCE_ERROR: number = 1;

const stripBeginAndEndSlash = (value: string) => {
  if (value.startsWith('/')) {
    if (value.length <= 1) {
      return '';
    }

    if (value.endsWith('/')) {
      if (value.length <= 2) {
        return '';
      }
      return value.substring(1, value.length - 1);
    }

    return value.substring(1);
  }

  if (value.endsWith('/')) {
    return value.substring(0, value.length - 1);
  }

  return value;
};

const countFolders = (value: string): number => {
  const cleanedValue = stripBeginAndEndSlash(value.trim());

  if (value.length === 0) {
    return 0;
  }

  let counter = 1;
  let i = 0, sz = cleanedValue.length;
  for (i = 0; i < sz; i++) {
    if (cleanedValue.charAt(i) === '/') {
      counter++;
    }
  }

  return counter;
};

const concatWithStartingSlash = (path: string, folder: string): string => {
  if (folder.startsWith('/')) {
    return path + folder.substring(1);
  }
  return path + folder;
};

const concatFolders = (path: string, folder: string): string => {
  if (folder.endsWith('/')) {
    return concatWithStartingSlash(path, folder);
  }
  return concatWithStartingSlash(path, folder) + '/';
};

export const CreateFolderModal = ({ bucketId, path, onFolderCreate, onClosed }: CreateFolderModalProps) => {

  const intl = useIntl();
  const createFolder = useCreateFolder(bucketId);

  const [folderName, updateFolderName] = useState<string>('');
  const [folderCount, updateFolderCount] = useState<number>(0);
  const [pathToCreate, updatePathToCreate] = useState<string>(path);
  const [translationMode, updateTranslationMode] = useState<string>('folder');
  const [errors, updateErrors] = useState<number>(0);

  const onFolderNameChanged = (value: string) => {
    updateErrors((validateFolderSlashSequence(value) ? 0 : FOLDER_PATH_SLASH_SEQUENCE_ERROR));

    updateFolderName(value);

    const folderFound = countFolders(value);
    updateFolderCount(folderFound);

    if (folderFound > 1) {
      updateTranslationMode('path');
    } else {
      updateTranslationMode('folder');
    }

    const expectedPath = concatFolders(path, value);
    updatePathToCreate(expectedPath);
  };

  const onCreateFolder = () => {
    createFolder(pathToCreate)
      .then(() => {
        onFolderCreate(pathToCreate);
      })
      .catch(() => {
        // TODO: handle errors
      });
  };

  return <AtdnModal>
        <div>
            <Title>
                <FormattedMessage id={'bucket.files.' + translationMode + '.modal.title'}/>
            </Title>

            <Input maxLength={200}
                   label={intl.formatMessage({ id: 'bucket.files.' + translationMode + '.modal.input' })}
                   value={folderName} required
                   onChange={(event) => onFolderNameChanged(event.target.value)}/>
            {(errors & FOLDER_PATH_SLASH_SEQUENCE_ERROR) === FOLDER_PATH_SLASH_SEQUENCE_ERROR && (
                <InputError>
                    <FormattedMessage id="bucket.files.folder.modal.input.error.double-slash"/>
                </InputError>
            )}

            <Label>
                <FormattedMessage id={'bucket.files.' + translationMode + '.modal.result'}
                                  values={{ folderPath: pathToCreate }}/>
            </Label>

            <BtnLine>
                <AtdnButton onClick={onCreateFolder} type="Success" disabled={folderCount === 0 || errors > 0}>
                    <FormattedMessage id="bucket.files.folder.modal.validation.btn"/>
                </AtdnButton>
                <AtdnButton onClick={onClosed}>
                    <FormattedMessage id="bucket.files.folder.modal.cancel.btn"/>
                </AtdnButton>
            </BtnLine>
        </div>
    </AtdnModal>;
};
