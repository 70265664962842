import { AtdnText, Colors, spacing } from '@ataden/design-system';
import React from 'react';
import styled from 'styled-components';
import { NoInvoiceYet } from './EmptyInvoice';

const Container = styled.div`
    margin: ${spacing(9)} 0 ${spacing(7)} 0;
`;

export const BillsComponent = () => {

  return (
        <Container>
            <AtdnText scale={6} color={Colors.Brown700}>
                Invoices
            </AtdnText>
            <NoInvoiceYet/>
        </Container>
  );
};
