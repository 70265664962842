import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import React, { useState } from 'react';
import { RoutePath } from '../AuthenticatedRouter';
import { useGetUserBusinessMembers } from '../business/repositories/BusinessRepository.hook';
import { BusinessMember } from '../business/domain/BusinessMember';
import { NavigationMenu } from './menu/NavigationMenu';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const Page = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
`;

const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    width: 100%;
    height: 100%;
`;

export const DashboardLayout = () => {
  const getUserBusinessMembers = useGetUserBusinessMembers();

  const [members, updateMembers] = useState<BusinessMember[]>();

  getUserBusinessMembers()
    .then((businessMember) => {
      updateMembers(businessMember);
    })
    .catch(() => {
      // TODO: handle loading error
    });

  if (members === undefined) {
    return <div></div>;
  }

  if (members.length === 0) {
    return <Navigate to={RoutePath.newProject}/>;
  }

  return (
        <Container>
            <NavigationMenu>
                <Page>
                    <PageContent>
                        <Outlet/>
                    </PageContent>
                </Page>
            </NavigationMenu>
        </Container>
  );
};
