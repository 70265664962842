import { createContext, ReactElement, useMemo } from 'react';
import { ActionCreatorWithPayload, configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'redux';
import { User } from './domain/User';
import { userStoreSlice } from './UserStore';

type UserContextProviderProps = {
  children: ReactElement;
};

type UserConfig = {
  store: Store;

  initCurrentUserAction: ActionCreatorWithPayload<User>;
  updateCurrentUserAction: ActionCreatorWithPayload<User>;
} & UserContextProviderProps;


export const UserContext = createContext<Partial<UserConfig>>({});

const Provider: React.FC<UserConfig> = (config: UserConfig) => {
  return <UserContext.Provider value={config}>{config.children}</UserContext.Provider>;
};

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const store: Store = useMemo(() => configureStore({
    reducer: userStoreSlice.reducer,
  }), []);

  const {
    initCurrentUser,
    updateCurrentUser,
  } = userStoreSlice.actions;

  return (
        <Provider
            store={store}
            initCurrentUserAction={initCurrentUser}
            updateCurrentUserAction={updateCurrentUser}
        >
            {children}
        </Provider>
  );
};
