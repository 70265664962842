import styled from 'styled-components';
import { MenuItem } from './MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
  getRouterBillingPath,
  getRouterBucketsPath,
  getRouterKeysPath,
  useGetProjectIdFromPath,
} from '../../AuthenticatedRouter';
import { AtadenLogo, Colors, spacing } from '@ataden/design-system';
import { useIntl } from 'react-intl';

const MENU_WIDTH: number = 200;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: ${MENU_WIDTH}px;
    max-width: ${MENU_WIDTH}px;
    height: 100%;

    background-color: ${Colors.Gray000};
    padding: ${spacing(5)} ${spacing(5)} ${spacing(5)} ${spacing(7)};

    box-shadow: ${Colors.Gray300} -1px 1px 5px 1px;
`;


const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 16px 0;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const TopContainer = styled.div`
    height: 100%;
`;

const BottomContainer = styled.div`
    margin-bottom: 32px;
`;

const ExtraSpace = styled.div`
    height: ${spacing(5)};
`;


export const VerticalNavigationMenu = () => {
  const logoutRedirection = process.env.REACT_APP__AUTH__LOGOUT!;

  const intl = useIntl();

  const projectId = useGetProjectIdFromPath();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const onLogOut = () => {
    logout({ logoutParams: { returnTo: logoutRedirection } });
  };

  const onBuckets = () => {
    navigate(getRouterBucketsPath(projectId));
  };

  const onApiKey = () => {
    navigate(getRouterKeysPath(projectId));
  };

  const onBilling = () => {
    navigate(getRouterBillingPath(projectId));
  };

  return (
        <Container>
            <ItemsContainer>
                <TopContainer>
                    {/*<MenuItem title={intl.formatMessage({ id: 'menu.dashboard' })} onClick={onBuckets}/>*/}
                    {/*<ExtraSpace/>*/}
                    <MenuItem title={intl.formatMessage({ id: 'menu.buckets' })} onClick={onBuckets}/>
                    <MenuItem title={intl.formatMessage({ id: 'menu.keys' })} onClick={onApiKey}/>
                </TopContainer>
                <BottomContainer>
                    <MenuItem title={intl.formatMessage({ id: 'menu.billing' })} onClick={onBilling}/>
                    {/*<MenuItem title={intl.formatMessage({ id: 'menu.settings' })} onClick={() => {*/}
                    {/*}}/>*/}
                    <ExtraSpace/>
                    <MenuItem title={intl.formatMessage({ id: 'menu.logout' })} onClick={onLogOut}/>
                </BottomContainer>
                <LogoContainer>
                    <AtadenLogo width="120px" height="40px"/>
                </LogoContainer>
            </ItemsContainer>
        </Container>
  );
};
