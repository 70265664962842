import { FolderExplorationItem } from '../domain/FileExploration';
import styled from 'styled-components';
import { AtdnCheckbox, AtdnCheckBoxState, AtdnText, Colors, FolderIcon, spacing } from '@ataden/design-system';
import { useIntl } from 'react-intl';
import { dateNumberToDateString } from '../../common/DateUtils';
import { ExplorerItemContainer } from './ExplorerItem.style';


type ExplorerFolderItemProps = {
  item: FolderExplorationItem;
  isSelected: boolean;

  onFolderClick: (path: string) => void;
  onSelectionChange: (selected: boolean) => void;
};

const ItemValue = styled(AtdnText)`
    display: flex;
    align-items: center;
`;

const ItemLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing(2)};
    cursor: pointer;
`;

const ItemName = styled(AtdnText)`
    padding-top: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const CustomFolderIcon = styled(FolderIcon)`
    min-width: 24px;
    width: 24px;
    max-width: 24px;
`;

export const ExplorerAvailableFolderItem = ({
  item,
  isSelected,
  onFolderClick,
  onSelectionChange,
}: ExplorerFolderItemProps) => {
  const intl = useIntl();

  const date = dateNumberToDateString(item.createdDate, intl.formatMessage({ id: 'common.date' }));

  const onFolderSelection = (checked: AtdnCheckBoxState) => {
    onSelectionChange(checked === 'CHECKED');
  };

  return (
        <ExplorerItemContainer>
            <AtdnCheckbox state={isSelected ? 'CHECKED' : 'UNCHECKED'} onChange={onFolderSelection}/>
            <ItemLabelContainer onClick={() => onFolderClick(item.path)}>
                <CustomFolderIcon color={Colors.Brown700}/>
                <ItemName>
                    {item.name}
                </ItemName>
            </ItemLabelContainer>
            <ItemValue>-</ItemValue>
            <ItemValue>{date}</ItemValue>
            <div/>

        </ExplorerItemContainer>
  );
};
