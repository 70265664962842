import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ToastHolder } from '@ataden/design-system';

const Container = styled.div`
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
`;

export const RootLayout = () => {

  return (
        <Container>
            <Outlet/>
            <ToastHolder/>
        </Container>
  );
};
