import { AtdnButton, spacing } from '@ataden/design-system';
import { useNavigate } from 'react-router-dom';
import { getRouterBucketCreationPath, useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

type BucketsToolbarProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
  margin-bottom: ${spacing(5)};
  gap: ${spacing(2)};

`;

export const BucketsToolbar = ({}: BucketsToolbarProps) => {
  const navigate = useNavigate();
  const projectId = useGetProjectIdFromPath();

  const onBucketCreation = () => {
    navigate(getRouterBucketCreationPath(projectId));
  };

  return (
        <Container>
            <AtdnButton size="normal" onClick={onBucketCreation}>
                <FormattedMessage id="buckets.create.cta"/>
            </AtdnButton>
        </Container>
  );

};