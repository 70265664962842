import { atadenAxiosInstance, Page, RestPage } from '../../common';
import { useProtectedDelete, useProtectedGet, useProtectedPost } from '../../common/ProtectedRestCall.hook';
import {
  ExplorationItem,
  ExplorationItemType,
  FileExplorationItemStatus,
  FileExplorationRequest,
  FolderExplorationItemStatus,
} from '../domain/FileExploration';

type RestFileExplorationItem = {
  id: string;
  type: ExplorationItemType;
  name: string;
  path: string;
  status: FileExplorationItemStatus & FolderExplorationItemStatus;
  size: number;
  createdDate: number;
  eraseDateAt: number;
};

const convertRestFileExplorationItemToDomain = (item: RestFileExplorationItem): ExplorationItem => {
  return {
    ...item,
  };
};


const convertRestPageFileExplorationItemToDomain = (rest: RestPage<RestFileExplorationItem>): Page<ExplorationItem> => {
  return {
    ...rest,
    items: rest.items.map(convertRestFileExplorationItemToDomain),
  };
};

const computeStatusParam = (showDeleted: boolean): string => {
  if (showDeleted) {
    return '&status=AVAILABLE&status=DELETED';
  }

  return '&status=AVAILABLE';
};


export const useExploreFiles = () => {
  const protectedGet = useProtectedGet<RestPage<RestFileExplorationItem>, Page<ExplorationItem>>(atadenAxiosInstance, convertRestPageFileExplorationItemToDomain);

  return (bucketId: string, request: FileExplorationRequest): Promise<Page<ExplorationItem>> => {
    return new Promise<Page<ExplorationItem>>((success, failure) => {

      protectedGet('/api/v1/buckets/' + bucketId + '/explorer?pageNumber=' + request.pageNumber + '&pageSize=' + request.pageSize + '&path=' + request.path + computeStatusParam(request.showDeleted))
        .then(success)
        .catch(failure);
    });
  };
};

const concatFolderAndFileParam = (foldersStr: string, fileStr: string): string => {
  if (foldersStr.length === 0) {
    if (fileStr.length === 0) {
      return '';
    }

    return fileStr;
  }

  if (fileStr.length === 0) {
    return foldersStr;
  }

  return foldersStr + '&' + fileStr;
};

export const useExploreDeleteItems = () => {
  const protectedDelete = useProtectedDelete(atadenAxiosInstance);

  return (bucketId: string, folderIds: string[], fileIds: string[]): Promise<void> => {
    return new Promise<void>((success, failure) => {

      const folderIdsParam = folderIds.map(id => 'folder=' + id);
      const fileIdsParam = fileIds.map(id => 'file=' + id);

      const foldersStr = folderIdsParam.join('&');
      const filesStr = fileIdsParam.join('&');
      const path = concatFolderAndFileParam(foldersStr, filesStr);

      protectedDelete('/api/v1/buckets/' + bucketId + '/explorer/items?' + path)
        .then(success)
        .catch(failure);
    });
  };
};

export const useExploreRecoverItems = () => {
  const protectedPost = useProtectedPost<void, void>(atadenAxiosInstance, () => {
  });

  return (bucketId: string, folderIds: string[], fileIds: string[]): Promise<void> => {
    return new Promise<void>((success, failure) => {

      const folderIdsParam = folderIds.map(id => 'folder=' + id);
      const fileIdsParam = fileIds.map(id => 'file=' + id);

      const foldersStr = folderIdsParam.join('&');
      const filesStr = fileIdsParam.join('&');
      const path = concatFolderAndFileParam(foldersStr, filesStr);

      protectedPost('/api/v1/buckets/' + bucketId + '/explorer/items/recover?' + path, undefined)
        .then(success)
        .catch(failure);
    });
  };
};
