import styled from 'styled-components';
import { Colors, spacing } from '@ataden/design-system';
import { ReactNode } from 'react';

type ExplorerItemContainerProps = {
  children: ReactNode | ReactNode[];
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 24px minmax(0px, 1fr) 100px 100px 48px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const ExplorerItemContainer = ({ children }: ExplorerItemContainerProps) => {
  
  return (
        <Container>
            {children}
        </Container>
  );
};
