import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import { RootLayout } from './layout/RootLayout';
import { WelcomePage } from './auth';
import { AuthenticatedRouter, RoutePath } from './AuthenticatedRouter';
import { LoginPage } from './auth/pages/LoginPage';
import { RegisterPage } from './auth/pages/RegisterPage';

export const AppRouter = () => {
  return (
        <BrowserRouter>
            <Routes>
                <Route element={<RootLayout/>}>
                    <Route path={RoutePath.welcome} element={<WelcomePage/>}/>
                    <Route path={RoutePath.login} element={<LoginPage/>}/>
                    <Route path={RoutePath.register} element={<RegisterPage/>}/>
                    <Route path={'/*'} element={<AuthenticatedRouter/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
  );
};
