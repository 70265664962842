import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@ataden/design-system';
import { RoutePath } from '../../AuthenticatedRouter';

export const RegisterPage = () => {
  const { loginWithRedirect, isLoading, isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    if (isAuthenticated) {
      logout({ logoutParams: { returnTo: RoutePath.register } });
    } else {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
        },
      });
    }

  }, [isLoading]);

  if (isLoading) {
    return <Loader/>;
  }


  return (
        <></>
  );
};
