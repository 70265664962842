import { useAuth0 } from '@auth0/auth0-react';
import { atadenAxiosInstance } from '../../common';
import { useProtectedGet } from '../../common/ProtectedRestCall.hook';

type RestFileCreated = {
  fileId: string;
};

export const useDownloadFile = () => {

  const protectedGet = useProtectedGet<Blob, Blob>(atadenAxiosInstance, (b) => b);

  return (bucketId: string, fileId: string): Promise<Blob> => {
    return new Promise<Blob>((success, failure) => {

      protectedGet('/api/v1/buckets/' + bucketId + '/files/' + fileId, 'blob')
        .then(success)
        .catch(failure);
    });
  };
};

export const useUploadFile = () => {
  const { getAccessTokenSilently } = useAuth0();

  return (bucketId: string, path: string, file: Blob, fileProgress: (progress: number) => void): Promise<string> => {
    return new Promise<string>((success, failure) => {

      getAccessTokenSilently()
        .then((token) => {

          const formData = new FormData();
          formData.append('path', path);
          formData.append('file', file);

          atadenAxiosInstance.postForm<RestFileCreated>('/protected/api/v1/buckets/' + bucketId + '/files/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token,
              },
              onUploadProgress: function (progressEvent) {
                fileProgress(progressEvent.loaded / progressEvent.total);
              },
            })
            .then((response) => {
              success(response.data.fileId);
            })
            .catch(failure);

        })
        .catch(failure);

    });
  };
};

