import React, { createContext, ReactElement } from 'react';
import { AuthenticationProvider } from '../auth/config/AuthenticationProvider';
import { BusinessContextProvider } from '../business/BusinessProvider';
import { TranslationConfigProvider } from './TranslationConfigProvider';
import English from '../assets/lang/en.json';
import French from '../assets/lang/fr.json';
import { UserContextProvider } from '../users/UserProvider';

export interface ConfigInterface {

}

export const ConfigContext = createContext<Partial<ConfigInterface>>({});

export const AppConfigProvider: React.FC<ConfigInterface> = ({ children, ...config }) => {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

type AppConfigProps = {
  children: ReactElement;
};

export const AppConfig = ({ children }: AppConfigProps) => {
  return (
        <TranslationConfigProvider translations={{ french: French, english: English }}>
            <AuthenticationProvider>
                <UserContextProvider>
                    <BusinessContextProvider>
                        {children}
                    </BusinessContextProvider>
                </UserContextProvider>
            </AuthenticationProvider>
        </TranslationConfigProvider>
  );
};
