import styled from 'styled-components';
import { Colors, ScreenSize } from '@ataden/design-system';

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 80px 100px 80px;
    border-radius: 40px;
    background-color: ${Colors.White};

    @media (max-width: ${ScreenSize.mobile}px) {
        border-radius: 0;
    }

    @media (max-width: ${ScreenSize.tablet}px) {
        padding: 40px 20px 40px 20px;
    }
`;
