import { Colors, DeleteIcon } from '@ataden/design-system';
import styled from 'styled-components';
import React, { useMemo, useState } from 'react';
import { Card } from '../../domain/Card';
import { CardDeletionModal } from './CardDeletionModal';
import { DefaultCardDeletionModal } from './DefaultCardDeletionModal';

type CardDeletionProps = {
  businessId: string;
  card: Card;
  onDeletion: () => void;
};


const DeleteIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const CardDeletion = ({
  businessId,
  card,
  onDeletion,
}: CardDeletionProps) => {

  const [showSelectionModal, updateSelectionModal] = useState<boolean>(false);

  const onCardDeleted = () => {
    updateSelectionModal(false);
    onDeletion();
  };

  const modal = useMemo(() => {
    if (!showSelectionModal) {
      return <></>;
    }

    if (card.current) {
      return <DefaultCardDeletionModal onClosed={() => updateSelectionModal(false)}/>;
    }
    return <CardDeletionModal card={card} businessId={businessId} onDeleted={onCardDeleted}
                                  onClosed={() => updateSelectionModal(false)}/>;
  }, [showSelectionModal, card.current]);

  return (
        <>
            <DeleteIconWrapper onClick={() => updateSelectionModal(true)}>
                <DeleteIcon color={Colors.Brown700}/>
            </DeleteIconWrapper>

            {modal}
        </>
  );
};
