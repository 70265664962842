import { useNavigate } from 'react-router-dom';
import { AtdnText, ChevronIcon, Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';

type GoBackToProps = {
  path: string;
  label: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    width: fit-content;

    cursor: pointer;
    margin-bottom: 20px;
`;

const BackText = styled(AtdnText)`
    padding-left: ${spacing(2)};
`;

export const GoBackTo = ({ path, label }: GoBackToProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(path);
  };

  return (
        <Container onClick={onClick}>
            <ChevronIcon direction="LEFT" width="16px" color={Colors.Brown700}/>
            <BackText type="important" color={Colors.Brown700}>{label}</BackText>
        </Container>);
};
