import React from 'react';
import styled from 'styled-components';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { CardPaymentMethodCreation } from './CardPaymentMethodCreation';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    gap: ${spacing(5)};
`;

export const NoCardPaymentYet = () => {

  return (
        <Container>
            <AtdnText color={Colors.Gray700}>
                No credit card yet.
            </AtdnText>
            <CardPaymentMethodCreation/>
        </Container>
  );
};
