import {
  AtdnCheckbox,
  AtdnCheckBoxState,
  AtdnText,
  Colors,
  DownloadIcon,
  FileIcon,
  spacing,
} from '@ataden/design-system';
import { FileExplorationItem } from '../domain/FileExploration';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { dateNumberToDateString } from '../../common/DateUtils';
import { byteTranslation } from '../../common/ByteTranslation';
import { ExplorerItemContainer } from './ExplorerItem.style';
import { useDownloadFile } from '../repository/FileRepository.hook';


type ExplorerAvailableFileItemProps = {
  bucketId: string;
  item: FileExplorationItem;
  isSelected: boolean;
  onSelectionChange: (selected: boolean) => void;
};


const ItemValue = styled(AtdnText)`
    display: flex;
    align-items: center;
`;

const ItemLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing(2)};
`;

const ItemName = styled(AtdnText)`
    padding-top: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Tools = styled.div`

`;

const IconWrapper = styled.div`
    cursor: pointer;
`;

const CustomFileIcon = styled(FileIcon)`
    min-width: 24px;
    width: 24px;
    max-width: 24px;
`;


export const ExplorerAvailableFileItem = ({
  bucketId,
  item,
  isSelected,
  onSelectionChange,
}: ExplorerAvailableFileItemProps) => {
  const intl = useIntl();
  const downloadFile = useDownloadFile();

  const date = dateNumberToDateString(item.createdDate, intl.formatMessage({ id: 'common.date' }));
  const size = item.size ? byteTranslation(item.size, intl) : '-';

  const onDownload = () => {
    downloadFile(bucketId, item.id)
      .then((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = item.name;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        // TODO: handle error
      });
  };

  const onFileSelection = (checked: AtdnCheckBoxState) => {
    onSelectionChange(checked === 'CHECKED');
  };

  return (
        <ExplorerItemContainer>
            <AtdnCheckbox state={isSelected ? 'CHECKED' : 'UNCHECKED'} onChange={onFileSelection}/>

            <ItemLabelContainer>
                <CustomFileIcon width="24px" color={Colors.Brown700}/>
                <ItemName>
                    {item.name}
                </ItemName>
            </ItemLabelContainer>
            <ItemValue>{size}</ItemValue>
            <ItemValue>{date}</ItemValue>
            <Tools>
                <IconWrapper onClick={onDownload}>
                    <DownloadIcon width="24px"/>
                </IconWrapper>
            </Tools>

        </ExplorerItemContainer>
  );
};
