import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { ProjectSelector } from '../../business/components/ProjectSelector';
import { Colors, MenuIcon, spacing } from '@ataden/design-system';
import { BannerMenuItem } from './BannerMenuItem';
import { useIntl } from 'react-intl';


type BannerNavigationMenuProps = {
  onMenuExpansion: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 100%;
    background-color: ${Colors.Gray100};
    padding: ${spacing(5)} ${spacing(5)} ${spacing(5)} ${spacing(6)};

    box-shadow: ${Colors.Gray300} -1px 1px 5px 1px;
    z-index: 1;
`;

const IconContainer = styled.div`
    display: flex;
    cursor: pointer;
    padding: ${spacing(2)};
`;

const MenuItems = styled.div`
    padding: 0 ${spacing(4)} 0 ${spacing(6)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;


export const BannerNavigationMenu = ({ onMenuExpansion }: BannerNavigationMenuProps) => {
  const logoutRedirection = process.env.REACT_APP__AUTH__LOGOUT!;

  const intl = useIntl();
  const { logout } = useAuth0();

  const onLogOut = () => {
    logout({ logoutParams: { returnTo: logoutRedirection } });
  };
 
  return (
        <Container>
            <IconContainer onClick={onMenuExpansion}>
                <MenuIcon width="24px" color={Colors.Brown700}/>
            </IconContainer>
            <MenuItems>
                <ProjectSelector/>
                <BannerMenuItem title={intl.formatMessage({ id: 'menu.logout' })} onClick={onLogOut}/>
            </MenuItems>
        </Container>
  );
};
