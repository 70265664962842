import { Card } from '../../domain/Card';
import styled from 'styled-components';
import { Colors, spacing } from '@ataden/design-system';
import { dateMonthAndYearToDateString, dateNumberToDateString } from '../../../common/DateUtils';
import { CardDefaultSelection } from './CardDefaultSelection';
import { CardDeletion } from './CardDeletion';

type CardComponentProps = {
  businessId: string;
  card: Card;
  onCardSelected: () => void;
  onCardDeleted: () => void;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 100px minmax(0px, 1fr) 175px 175px 100px;
  column-gap: 16px;
  width: 100%;

  padding: ${spacing(6)};
  border-bottom: 1px solid ${Colors.Gray100};
`;


export const CardComponent = ({ businessId, card, onCardSelected, onCardDeleted }: CardComponentProps) => {
  const createdDate = dateNumberToDateString(card.createdDate * 1000, 'DD/MM/YYYY');
  const expirationDate = dateMonthAndYearToDateString(card.expMonth, card.expYear, 'MM/YYYY');


  return (
        <>
            <Container>
                <CardDefaultSelection businessId={businessId} card={card} onSelection={onCardSelected}/>
                <div>
                    XXXX XXXX XXXX {card.last4}
                </div>
                <div>
                    {createdDate}
                </div>
                <div>
                    {expirationDate}
                </div>
                <CardDeletion businessId={businessId} card={card} onDeletion={onCardDeleted}/>
            </Container>
        </>

  );
};
