const numberToString = (num: number, digitCount: number): string => {
  const result = num.toString();
  if (result.length < digitCount) {
    return '0'.repeat(digitCount - result.length) + result;
  }

  return result;
};

export const dateNumberToDateString = (date: number, dateFormat: string): string => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();


  let tempStr = dateFormat;
  tempStr = tempStr.replace('DD', numberToString(day, 2));
  tempStr = tempStr.replace('MM', numberToString(month + 1, 2));
  tempStr = tempStr.replace('YYYY', numberToString(year, 4));

  return tempStr;
};

export const dateMonthAndYearToDateString = (month: number, year: number, dateFormat: string): string => {

  let tempStr = dateFormat;
  tempStr = tempStr.replace('MM', numberToString(month, 2));
  tempStr = tempStr.replace('YYYY', numberToString(year, 4));

  return tempStr;
};


export const dateStringToDateNumber = (dateStr: string, dateFormat: string): number => {
  const dateParts = dateFormat.split('/');
  const dateItems = dateStr.split('/');

  let day = 0;
  let month = 0;
  let year = 0;

  dateParts.forEach((datePart, index) => {
    if (datePart === 'DD') {
      day = parseInt(dateItems[index]);
      return;
    }
    if (datePart === 'MM') {
      month = parseInt(dateItems[index]) - 1;
      return;
    }
    if (datePart === 'YYYY') {
      year = parseInt(dateItems[index]);
      return;
    }
  });

  return new Date(year, month, day).getTime();
};


export const timeNumberToTimeString = (time: number, timeFormat: string): string => {
  const dateObj = new Date(time);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();

  const timeParts = timeFormat.split(':');

  const convertedTimeParts = timeParts.map((timePart) => {
    if (timePart === 'hh') {
      return numberToString(hours, 2);
    }
    if (timePart === 'mm') {
      return numberToString(minutes, 2);
    }
    if (timePart === 'ss') {
      return numberToString(seconds, 2);
    }

    return '';
  });

  return convertedTimeParts.join(':');
};

export const timeStringToTimeNumber = (timeStr: string, timeFormat: string): number => {
  const timeParts = timeFormat.split(':');
  const timeItems = timeStr.split(':');

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  timeParts.forEach((timePart, index) => {
    if (timePart === 'hh') {
      hours = parseInt(timeItems[index]);
      return;
    }
    if (timePart === 'mm') {
      minutes = parseInt(timeItems[index]);
      return;
    }
    if (timePart === 'ss') {
      seconds = parseInt(timeItems[index]);
      return;
    }
  });

  return new Date(0, 0, 0, hours, minutes, seconds).getTime();
};
