import { ExplorerItemHeader } from './ExplorerItemHeader';
import { AtdnText, Colors } from '@ataden/design-system';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const EmptyFileContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
`;

export const EmptyFolderMessage = () => {
  const nothing = () => {

  };

  return (
        <>
            <ExplorerItemHeader selectMode='NONE' onSelectAll={nothing} onUnselectAll={nothing}/>
            <EmptyFileContainer>
                <AtdnText color={Colors.Gray700}>
                    <FormattedMessage id="bucket.files.folder.empty"/>
                </AtdnText>
            </EmptyFileContainer>
        </>
  );
};
