import { Bucket } from '../domain/Bucket';
import { dateNumberToDateString } from '../../common/DateUtils';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { useNavigate } from 'react-router-dom';
import { getRouterBucketPath } from '../../AuthenticatedRouter';
import { byteTranslation } from '../../common/ByteTranslation';

type BucketInfoProps = {
  projectId: string;
  bucket: Bucket
};

const Container = styled.div`
  display: grid;
  grid-template-columns: /*24px*/ minmax(0px, 1fr) 100px 100px;
  column-gap: 16px;

  width: 100%;
  padding: ${spacing(6)};
  margin-bottom: ${spacing(4)};
  border-bottom: 1px solid ${Colors.Gray100};
`;

const BucketName = styled(AtdnText)`
  cursor: pointer;
  width: fit-content;

  :hover {
    text-decoration: underline;
  }
`;

export const BucketInfo = ({ projectId, bucket }: BucketInfoProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const date = dateNumberToDateString(bucket.createdDate, intl.formatMessage({ id: 'common.date' }));
  const size = bucket.size ? byteTranslation(bucket.size, intl) : '-';

  const onNameClicked = () => {
    navigate(getRouterBucketPath(projectId, bucket.id));
  };

  return (
        <Container>
            <div>
                <div onClick={onNameClicked}>
                    <BucketName color={Colors.Brown700} type="important">{bucket.name}</BucketName>
                </div>
            </div>
            <div>
                <AtdnText>{size}</AtdnText>
            </div>
            <div>
                <AtdnText>{date}</AtdnText>
            </div>
        </Container>
  );
};
