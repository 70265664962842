import { FileExplorationItem } from '../domain/FileExploration';
import { ExplorerAvailableFileItem } from './ExplorerAvailableFileItem';
import { ReactElement } from 'react';
import { ExplorerDeletedFileItem } from './ExplorerDeletedFileItem';


type ExplorerFileItemProps = {
  bucketId: string;
  item: FileExplorationItem;
  isSelected: boolean;
  onSelectionChange: (selected: boolean) => void;
  onRecovered: () => void;
};

 
export const ExplorerFileItem = ({
  bucketId,
  item,
  isSelected,
  onSelectionChange,
  onRecovered,
}: ExplorerFileItemProps): ReactElement => {

  switch (item.status) {
    case 'UPLOADED':
      return <ExplorerAvailableFileItem bucketId={bucketId} item={item} isSelected={isSelected}
                                              onSelectionChange={onSelectionChange}/>;

    case 'DELETED':
    case 'FOLDER_DELETED':
    case 'BUCKET_DELETED':
      return <ExplorerDeletedFileItem bucketId={bucketId} item={item} isSelected={isSelected}
                                            onSelectionChange={onSelectionChange} onRecovered={onRecovered}/>;
  }

};
