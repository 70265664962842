import { BucketDetails } from '../../domain/Bucket';
import { AtdnSwitch, AtdnText, pushToast, spacing } from '@ataden/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useUpdateBucket } from '../../repository/BucketRepository.hook';
import { FormattedMessage, useIntl } from 'react-intl';

type UpdateBucketShowDeletionOptionProps = {
  bucket: BucketDetails;
  onBucketUpdated: (bucket: BucketDetails) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 ${spacing(8)} 0;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled(AtdnText)`
  margin-bottom: ${spacing(3)};
`;


export const UpdateBucketShowDeletionOption = ({ bucket, onBucketUpdated }: UpdateBucketShowDeletionOptionProps) => {

  const intl = useIntl();
  const updateBucket = useUpdateBucket();


  const [isProgressing, updateProgression] = useState<boolean>(false);

  const onChange = (value: boolean) => {

    updateProgression(true);
    updateBucket(bucket.id, {
      showDeletion: value,
    })
      .then(() => {
        onBucketUpdated({ ...bucket, showDeletion: value });

        pushToast({
          title: intl.formatMessage({ id: 'bucket.settings.show-deletion.toast.success.title' }),
          description: intl.formatMessage({ id: 'bucket.settings.show-deletion.toast.success.description' }),
          type: 'SUCCESS',
          durationInMs: 15000,
        });
      })
      .catch(() => {
        // TODO: handle error
      })
      .finally(() => {
        updateProgression(false);
      });

  };

  return (
        <Container>
            <Header>
                <Description>
                    <FormattedMessage id="bucket.settings.show-deletion.description"/>
                </Description>
            </Header>
            <AtdnSwitch checked={bucket.showDeletion} onChange={(evt) => onChange(evt.target.checked)}
                        disabled={isProgressing}/>
        </Container>

  );
};
