import { useGetScreenEventBus, useGetScreenIsTablet } from '../common/screen/ScreenProvider';
import { ReactNode, useEffect, useState } from 'react';
import { MobileAtadenLayout } from './MobileAtadenLayout';
import { DesktopAtadenLayout } from './DesktopAtadenLayout';

type AtadenLayoutProps = {
  children: ReactNode;

  withMobileCompanyDescription?: boolean;
};

export const AtadenLayout = ({ withMobileCompanyDescription = true, children }: AtadenLayoutProps) => {

  const screenEventBus = useGetScreenEventBus();
  const getScreenIsTablet = useGetScreenIsTablet();

  const [isTablet, updateIsTablet] = useState<boolean>(getScreenIsTablet());

  useEffect(() => {
    const onSizeChanged = () => {
      updateIsTablet(getScreenIsTablet());
    };

    screenEventBus.on('onSizeChanged', onSizeChanged);
    return () => {
      screenEventBus.off('onSizeChanged', onSizeChanged);
    };
  }, [screenEventBus, getScreenIsTablet]);

  if (isTablet) {
    return <MobileAtadenLayout
            withMobileCompanyDescription={withMobileCompanyDescription}>{children}</MobileAtadenLayout>;
  }
  return <DesktopAtadenLayout>{children}</DesktopAtadenLayout>;

};
