import { AtdnButton } from '@ataden/design-system';
import { CardPaymentMethodCreationModal } from './CardPaymentMethodCreationModal';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const CardPaymentMethodCreation = () => {

  const [showCreditCardCreationModal, updateCreditCardCreationModalVisibility] = useState<boolean>(false);

  const onCreditCardCreationBtn = () => {
    updateCreditCardCreationModalVisibility(true);
  };

  return (
        <>
            <AtdnButton onClick={onCreditCardCreationBtn}>
                <FormattedMessage id="billing.cards.create.cta"/>
            </AtdnButton>
            {showCreditCardCreationModal &&
                <CardPaymentMethodCreationModal onClosed={() => updateCreditCardCreationModalVisibility(false)}/>}
        </>
  );
};
