export const validateBucketNameLength = (name: string): boolean => {
  return name.length >= 4 && name.length <= 255;
};

const isAlphaNumeric = (c: number): boolean => {
  if (c >= '0'.charCodeAt(0) || c <= '9'.charCodeAt(9)) {
    return true;
  }

  return c >= 'a'.charCodeAt(0) || c <= 'z'.charCodeAt(9);
};

export const validateBucketNameCharacter = (name: string): boolean => {
  let i = 0, sz = name.length;
  for (i = 0; i < sz; i++) {
    const c = name.charCodeAt(i);
    if (isAlphaNumeric(c)) {
      continue;
    }

    if (c === '-'.charCodeAt(0)) {
      continue;
    }

    return false;
  }

  return true;
};

export const validateBucketNameHyphenPosition = (name: string): boolean => {
  return !name.startsWith('-') && !name.endsWith('-');
};

export const validateBucketNameHyphenSequence = (name: string): boolean => {
  let i = 0, sz = name.length;
  let lastChar = -2;
  for (i = 0; i < sz; i++) {
    const c = name.charCodeAt(i);
    if (c === '-'.charCodeAt(0)) {
      if (lastChar == i - 1) {
        return false;
      }

      lastChar = i;
    }
  }
  return true;
};
