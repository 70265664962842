import styled from 'styled-components';
import { Colors, spacing } from '@ataden/design-system';


const Container = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 8px 0 8px 0;
    margin: 8px 0 8px 0;
    font-weight: 400;
    color: ${Colors.Brown700};
    font-size: 18px;
    height: ${spacing(9)};

    &:hover {
        font-weight: 600;
    }
`;

type MenuItemProps = {
  title: string;

  onClick: () => void;
};

export const MenuItem = ({ title, onClick }: MenuItemProps) => {

  return (
        <Container onClick={onClick}>
            {title}
        </Container>
  );
};
