import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useContext } from 'react';
import { UserContext } from './UserProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'redux';
import { User } from './domain/User';


export type UserState = Readonly<{
  currentUser: User | undefined;
}>;

const initialState: UserState = { currentUser: undefined };


export const userStoreSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    initCurrentUser(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
    },
    updateCurrentUser(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
    },
  },
});

export const useUserStore = (): Store => {
  return useContext(UserContext).store!;
};


export const useGetStoreCurrentUser = () => {
  const store = useUserStore();

  return (): User | undefined => {
    return store.getState().currentUser;
  };
};


export const useInitCurrentUser = () => {
  const store = useUserStore();
  const initCurrentUserMember = useContext(UserContext).initCurrentUserAction!;

  return (user: User) => {
    store.dispatch(initCurrentUserMember(user));
  };
};

export const useApproveCurrentUserGeneralTerms = () => {
  const store = useUserStore();
  const updateUser = useContext(UserContext).updateCurrentUserAction!;

  return () => {
    const currentUser = store.getState().currentUser;
    if (!currentUser) {
      return;
    }

    store.dispatch(updateUser({
      ...currentUser,
      license: true,
    }));
  };

};
