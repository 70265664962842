import styled from 'styled-components';
import { useRef } from 'react';
import { Colors, Tooltip } from '@ataden/design-system';

type SelectedProjectTitleProps = {
  title: string;
};

const SelectedTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    color: ${Colors.Brown700};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 42px;
    max-width: fit-content;
`;

export const SelectedProjectTitle = ({ title }: SelectedProjectTitleProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  let supportTooltip = false;
  if (containerRef.current) {
    const box = containerRef.current.getBoundingClientRect();
    supportTooltip = box.width > 200;
  }

  return (
        <SelectedTitle ref={containerRef}>
            {supportTooltip ?
              (
                    <Tooltip text={title}>
                        {title}
                    </Tooltip>
              ) : <>{title}</>
            }
        </SelectedTitle>
  );
};
