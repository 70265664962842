import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { useGetProjectIdFromPath } from '../../../AuthenticatedRouter';
import { useStripePromise } from '../../config/Payment.hook';
import { useCreateCardPaymentSession } from '../../repositories/PaymentRepository.hook';


export const StripeCardRegistration = () => {
  const projectId = useGetProjectIdFromPath();

  const stripePromise = useStripePromise();

  const createCardPaymentSession = useCreateCardPaymentSession(projectId);

  const options = { fetchClientSecret: createCardPaymentSession };

  return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout/>
            </EmbeddedCheckoutProvider>
        </div>
  );
};
