import { Bucket } from '../../domain/Bucket';
import styled from 'styled-components';
import { spacing } from '@ataden/design-system';
import { S3Section } from './S3Section';

type BucketApiTabProps = {
  bucket: Bucket;
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${spacing(8)} ${spacing(5)} 0 ${spacing(5)};
`;

export const BucketApiTab = ({ bucket }: BucketApiTabProps) => {

  return (
        <TabContainer>
            <S3Section bucket={bucket}/>
        </TabContainer>
  );
};