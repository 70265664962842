import { AtdnButton, AtdnText, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

type CreatedFolderToastContentProps = {
  onGoToFolder: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(4)};
`;

export const CreatedFolderToastContent = ({ onGoToFolder }: CreatedFolderToastContentProps) => {
  return (
        <Container>
            <AtdnText>
                <FormattedMessage id="bucket.files.folder.toast.success.description"/>
            </AtdnText>
            <AtdnButton onClick={onGoToFolder} type="Success">
                <FormattedMessage id="bucket.files.folder.toast.success.btn"/>
            </AtdnButton>
        </Container>
  );
};
