import { atadenAxiosInstance } from '../../common';
import { useProtectedGet, useProtectedPost } from '../../common/ProtectedRestCall.hook';
import { User } from '../domain/User';
import { useApproveCurrentUserGeneralTerms, useGetStoreCurrentUser, useInitCurrentUser } from '../UserStore';

type RestUser = {
  id: string;
  license: boolean;
};


const convertRestUserToDomain = (user: RestUser): User => {
  return {
    ...user,
  };
};


export const useGetCurrentUser = () => {
  const protectedGet = useProtectedGet<RestUser, User>(atadenAxiosInstance, convertRestUserToDomain);

  const initCurrentUser = useInitCurrentUser();
  const getCurrentUser = useGetStoreCurrentUser();

  return (): Promise<User> => {
    return new Promise<User>((success, failure) => {

      const currentUser = getCurrentUser();
      if (currentUser) {
        success(currentUser);
        return;
      }

      protectedGet('/api/v1/users/me')
        .then(user => {
          initCurrentUser(user);
          success(user);
        })
        .catch(failure);
    });
  };
};

export const useApproveGeneralTerms = () => {
  const protectedPost = useProtectedPost<void, void>(atadenAxiosInstance, () => {
  });
  const approveCurrentUserGeneralTerms = useApproveCurrentUserGeneralTerms();

  return (): Promise<void> => {
    return new Promise<void>((success, failure) => {

      protectedPost('/api/v1/users/approve-license', undefined)
        .then(() => {
          approveCurrentUserGeneralTerms();
          success();
        })
        .catch(failure);
    });
  };
};
