import { AtadenLayout } from '../../layout/AtadenLayout';
import { LoginComponent } from './LoginComponent';
import { useAuth0 } from '@auth0/auth0-react';

export const WelcomePage = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  const onLoginRequested = () => {
    loginWithRedirect();
  };

  return (
        <AtadenLayout>
            <LoginComponent isLoading={isLoading} isAuthenticated={isAuthenticated}
                            onLoginRequested={onLoginRequested}/>
        </AtadenLayout>
  );
};
