import React, { useMemo, useState } from 'react';
import { AtdnButton } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import { ExplorationItem } from '../domain/FileExploration';
import { DeleteItemsModal } from './DeleteItemsModal';
import { BucketDetails } from '../domain/Bucket';

type DeleteItemsButtonProps = {
  bucket: BucketDetails;

  selectedItems: ExplorationItem[];

  onItemsDeleted: () => void;
};

 
export const DeleteItemsButton = ({ bucket, selectedItems, onItemsDeleted }: DeleteItemsButtonProps) => {

  const [isModalVisible, updateModalVisibility] = useState<boolean>(false);

  const computeButtonLabel = useMemo(() => {
    if (selectedItems.length > 1) {
      return <FormattedMessage id="bucket.files.delete.items.btn" values={{ count: selectedItems.length }}/>;
    }

    if (selectedItems[0].type === 'FOLDER') {
      return <FormattedMessage id="bucket.files.delete.folder.btn"/>;
    }

    return <FormattedMessage id="bucket.files.delete.file.btn"/>;
  }, [selectedItems]);

  const onDeleted = () => {
    updateModalVisibility(false);
    onItemsDeleted();
  };

  return (
        <>
            <AtdnButton size='normal' type="Danger" onClick={() => {
              updateModalVisibility(true);
            }}>
                {computeButtonLabel}
            </AtdnButton>

            {isModalVisible && (<DeleteItemsModal bucket={bucket} items={selectedItems}
                                                  onClosed={() => updateModalVisibility(false)}
                                                  onDeleted={onDeleted}/>)}

        </>
  );

};
