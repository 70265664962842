import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { RoutePath } from '../AuthenticatedRouter';

export const AuthenticatedLayout = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    // TODO: add a loader ?
    return <></>;
  }

  if (!isAuthenticated) {
    return <Navigate to={RoutePath.welcome}/>;
  }

  return (
        <Outlet/>
  );
};
