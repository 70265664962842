import { useFetchBuckets } from '../repository/BucketRepository.hook';
import { useEffect, useState } from 'react';
import { Bucket } from '../domain/Bucket';
import styled from 'styled-components';
import { BucketInfo } from './BucketInfo';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';

type BucketListProps = {
  projectId: string;
  pageSize: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: /*24px*/ minmax(0px, 1fr) 100px 100px;
  border-bottom: 1px solid ${Colors.Gray100};
  padding: ${spacing(6)};
  margin-bottom: ${spacing(4)};
`;


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BucketList = ({ projectId, pageSize }: BucketListProps) => {
  const fetchBuckets = useFetchBuckets();

  const [buckets, updateBuckets] = useState<Bucket[]>([]);

  useEffect(() => {
    fetchBuckets(projectId)
      .then(result => updateBuckets(result.items))
      .catch(() => {
        // TODO: handle errors
      });
  }, []);

  return (
        <Container>
            <HeaderContainer>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="buckets.list.header.name"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="buckets.list.header.size"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="buckets.list.header.createdDate"/>
                </AtdnText>
            </HeaderContainer>
            {buckets.map((bucket) => (
                <BucketInfo key={bucket.id} projectId={projectId} bucket={bucket}/>
            ))}
        </Container>
  );
};
