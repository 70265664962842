import { atadenAxiosInstance } from '../../common';
import { useProtectedPost } from '../../common/ProtectedRestCall.hook';

type RestFolderCreated = {
  folderId: string;
};


export const useCreateFolder = (bucketId: string) => {
  const protectedPost = useProtectedPost<RestFolderCreated, string>(atadenAxiosInstance, (response) => response.folderId);

  return (path: string): Promise<string> => {
    return new Promise<string>((success, failure) => {

      protectedPost('/api/v1/buckets/' + bucketId + '/folders?path=' + path, undefined)
        .then(folderId => {
          success(folderId);
        })
        .catch(failure);
    });
  };
};


