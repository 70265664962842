import React from 'react';
import styled from 'styled-components';
import { AppConfig } from './config/AppConfigProvider';
import { AppRouter } from './AppRouter';
import { ScreenProvider } from './common/screen/ScreenProvider';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

function App() {

  return (
        <AppConfig>
            <AppContainer>
                <ScreenProvider>
                    <AppRouter/>
                </ScreenProvider>
            </AppContainer>
        </AppConfig>
  );
}

export default App;
