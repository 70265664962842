import { ApiKey } from '../domain/ApiKey';
import { ApiKeyLine } from './ApiKeyLine';
import styled from 'styled-components';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';

type ApiKeyTableProps = {
  apiKeys: ApiKey[];
  onApiKeyDeleted: (apiKey: ApiKey) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ApiKeyTableHeader = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 175px 50px;
    column-gap: 16px;
    width: 100%;

    padding: ${spacing(6)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const ApiKeyTable = ({ apiKeys, onApiKeyDeleted }: ApiKeyTableProps) => {

  return (
        <Container>
            <ApiKeyTableHeader>
                <div>
                    <AtdnText type="important" color={Colors.Brown700}>
                        <FormattedMessage id="keys.list.header.accessId"/>
                    </AtdnText>

                </div>
                <div>
                    <AtdnText type="important" color={Colors.Brown700}>
                        <FormattedMessage id="keys.list.header.createdDate"/>
                    </AtdnText>
                </div>
            </ApiKeyTableHeader>

            {apiKeys.map(apiKey => (
                <ApiKeyLine key={apiKey.accessId} apiKey={apiKey} onApiKeyDeleted={() => onApiKeyDeleted(apiKey)}/>
            ))}
        </Container>
  );
};
