import { BusinessMember } from './domain/BusinessMember';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useContext } from 'react';
import { BusinessContext } from './BusinessProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'redux';
import { BusinessInfo } from './domain/BusinessInfo';


export type BusinessState = Readonly<{
  businessMember?: BusinessMember[],
  currentBusiness?: BusinessInfo
}>;

const initialState: BusinessState = { businessMember: [], currentBusiness: undefined };


export const businessStoreSlice = createSlice({
  name: 'businesses',
  initialState: initialState,
  reducers: {
    initBusinessMember(state, action: PayloadAction<BusinessMember[]>) {
      state.businessMember = action.payload;
    },
    initCurrentBusiness(state, action: PayloadAction<BusinessInfo | undefined>) {
      state.currentBusiness = action.payload;
    },
  },
});

export const useBusinessStore = (): Store => {
  return useContext(BusinessContext).store!;
};


export const useGetBusinessMembers = () => {
  const store = useBusinessStore();

  return (): BusinessMember[] => {
    return store.getState().businessMember;
  };
};


export const useInitBusinessMembers = () => {
  const store = useBusinessStore();
  const initBusinessMember = useContext(BusinessContext).initBusinessMemberAction!;

  return (businessMembers: BusinessMember[]) => {
    store.dispatch(initBusinessMember(businessMembers));
  };
};


export const useGetCurrentBusinessInfo = () => {
  const store = useBusinessStore();

  return (): BusinessInfo | undefined => {
    return store.getState().currentBusiness;
  };
};


export const useInitCurrentBusiness = () => {
  const store = useBusinessStore();
  const initBusiness = useContext(BusinessContext).initCurrentBusinessAction!;

  return (business: BusinessInfo | undefined) => {
    store.dispatch(initBusiness(business));
  };
};
