import { useAuth0 } from '@auth0/auth0-react';
import { Axios } from 'axios';
import { ServerException } from './ServerException';

type SupportedResponseType = 'json' | 'blob';

type FunctionGetType = <TRest, TDomain>(axios: Axios, converter: (rest: TRest) => TDomain) => (url: string, responseType?: SupportedResponseType) => Promise<TDomain>;

const handleServerError = (err: any, failure: (reason?: any) => void) => {
  const response = err.response;
  failure({
    ...response.data,
    httpStatus: response.status,
  } as ServerException);
};

export const useProtectedGet: FunctionGetType =
    <TRest, TDomain>(axios: Axios, converter: (rest: TRest) => TDomain): (url: string) => Promise<TDomain> => {

      const { getAccessTokenSilently } = useAuth0();

      return (url: string, responseType?: SupportedResponseType): Promise<TDomain> => {

        return new Promise<TDomain>((success, failure) => {

          getAccessTokenSilently()
            .then((token) => {

              axios.get<TRest>('/protected' + url, {
                responseType: responseType,
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              })
                .then((response) => {
                  success(converter(response.data));
                })
                .catch(failure);
            })
            .catch(err => handleServerError(err, failure));
        });
      };
    };

type FunctionPostType = <TRest, TDomain>(axios: Axios, converter: (rest: TRest) => TDomain) => (url: string, body: any) => Promise<TDomain>;

export const useProtectedPost: FunctionPostType =
    <TRest, TDomain>(axios: Axios, converter: (rest: TRest) => TDomain): (url: string, body: any) => Promise<TDomain> => {

      const { getAccessTokenSilently } = useAuth0();

      return (url: string, body: any): Promise<TDomain> => {

        return new Promise<TDomain>((success, failure) => {

          getAccessTokenSilently()
            .then((token) => {

              axios.post<TRest>('/protected' + url, body, {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              })
                .then((response) => {
                  success(converter(response.data));
                })
                .catch(err => handleServerError(err, failure));
            })
            .catch(failure);
        });
      };
    };


type FunctionPatchType = <TRest, TDomain>(axios: Axios, converter: (rest: TRest) => TDomain) => (url: string, body: any) => Promise<TDomain>;

export const useProtectedPatch: FunctionPatchType =
    <TRest, TDomain>(axios: Axios, converter: (rest: TRest) => TDomain): (url: string, body: any) => Promise<TDomain> => {

      const { getAccessTokenSilently } = useAuth0();

      return (url: string, body: any): Promise<TDomain> => {

        return new Promise<TDomain>((success, failure) => {

          getAccessTokenSilently()
            .then((token) => {

              axios.patch<TRest>('/protected' + url, body, {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              })
                .then((response) => {
                  success(converter(response.data));
                })
                .catch(err => handleServerError(err, failure));
            })
            .catch(failure);
        });
      };
    };


export const useProtectedDelete = (axios: Axios) => {

  const { getAccessTokenSilently } = useAuth0();

  return (url: string): Promise<void> => {

    return new Promise<void>((success, failure) => {

      getAccessTokenSilently()
        .then((token) => {

          axios.delete<void>('/protected' + url, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
            .then(() => {
              success();
            })
            .catch(err => handleServerError(err, failure));
        })
        .catch(failure);
    });
  };
};

