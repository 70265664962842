import { useGetBusinessInfo, useSetCurrentBusiness } from '../repositories/BusinessRepository.hook';
import { useEffect, useState } from 'react';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { SelectedProjectTitle } from './SelectedProjectTitle';

export const ProjectSelector = () => {
  const projectId = useGetProjectIdFromPath();

  const getBusinessInfo = useGetBusinessInfo();
  const setCurrentBusiness = useSetCurrentBusiness();

  const [currentBusinessName, updateCurrentBusinessName] = useState<string>();
  
  useEffect(() => {
    getBusinessInfo(projectId)
      .then(info => {
        setCurrentBusiness(info);
        updateCurrentBusinessName(info.name);
      })
      .catch(() => {
        // TODO: handle error
      },
      );
  }, []);

  if (!currentBusinessName) {
    return <></>;
  }

  return (
        <>
            <SelectedProjectTitle title={currentBusinessName}/>
        </>
  );
};
