import { getRouterHomePath, useGetProjectIdFromPath } from '../AuthenticatedRouter';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const ProjectPage = () => {
  const projectId = useGetProjectIdFromPath();

  const navigate = useNavigate();

  useEffect(() => {
    navigate(getRouterHomePath(projectId));
  }, [projectId]);

  return <></>;
};
