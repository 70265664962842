import React, { useState } from 'react';
import { AtdnButton, pushToast } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateFolderModal } from './CreateFolderModal';
import { getRouterBucketPath, useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { useNavigate } from 'react-router-dom';
import { CreatedFolderToastContent } from './CreatedFolderToastContent';

type AddFolderButtonProps = {
  bucketId: string;

  path: string;

  onFolderCreated: (createdPath: string) => void;
};

export const CreateFolderButton = ({ bucketId, path, onFolderCreated }: AddFolderButtonProps) => {
  const projectId = useGetProjectIdFromPath();

  const intl = useIntl();
  const navigate = useNavigate();

  const [modalVisibility, updateModalVisibility] = useState<boolean>();

  const onOpenModal = () => {
    updateModalVisibility(true);
  };

  const onModalClosed = () => {
    updateModalVisibility(false);
  };

  const onNewFolderNavigation = (folderPath: string) => {
    navigate(getRouterBucketPath(projectId, bucketId!, 1, folderPath));
  };

  const folderCreated = (folderPath: string) => {
    onFolderCreated(folderPath);
    pushToast({
      title: intl.formatMessage({ id: 'bucket.files.folder.toast.success.title' }),
      description: <CreatedFolderToastContent onGoToFolder={() => onNewFolderNavigation(folderPath)}/>,
      type: 'SUCCESS',
      durationInMs: 15000,
    });
    updateModalVisibility(false);
  };

  return (
        <>
            <AtdnButton size='normal' type="Success" onClick={onOpenModal}>
                <FormattedMessage id="bucket.files.folder.title"/>
            </AtdnButton>

            {modalVisibility && (
                <CreateFolderModal bucketId={bucketId} path={path} onFolderCreate={folderCreated}
                                   onClosed={onModalClosed}/>
            )}
        </>
  );

};
