import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { Panel } from '../common/Panel';
import { AtadenLogo, AtdnText, Colors, ScreenSize } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';

type MobileAtadenLayoutProps = {
  children: ReactNode;

  withMobileCompanyDescription: boolean;
};


const Page = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${Colors.Gray100};
`;

const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;

    width: 100%;
    height: 100%;

    background-image: url("/blocks.jpg");
    background-size: cover;
    background-color: ${Colors.Purple300};
`;

const ChildrenContainer = styled(Panel)`
    justify-content: center;
    align-items: center;

    @media (max-width: ${ScreenSize.mobile}px) {
        margin: 0;
        border-radius: 0;
    }
`;

const CompanyDescription = styled(AtdnText)`
    color: ${Colors.White};
    text-align: center;

    margin-top: 100px;
`;

const MojoDescription = styled(AtdnText)`
    color: ${Colors.White};
    text-align: center;

    margin: 20px 20px 120px 20px;

    @media (max-width: ${ScreenSize.mobile}px) {
        margin: 20px 0 120px 0;
    }
`;

const LogoWrapper = styled.div`
    margin: 20px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: center;
`;

export const MobileAtadenLayout = ({ children, withMobileCompanyDescription }: MobileAtadenLayoutProps) => {

  return (
        <Page>
            <ContainerForm>
                <LogoWrapper>
                    <AtadenLogo width="196px" height="45px" whiteMode/>
                </LogoWrapper>

                {withMobileCompanyDescription ? (
                    <>
                        <ChildrenContainer>
                            {children}
                        </ChildrenContainer>
                        <CompanyDescription scale={8}>
                            <FormattedMessage id="auth.login.company.title"/>
                        </CompanyDescription>
                        <MojoDescription scale={6}>
                            <FormattedMessage id="auth.login.company.description"/>
                        </MojoDescription>
                    </>
                ) : (
                    <Content>
                        <ChildrenContainer>
                            {children}
                        </ChildrenContainer>
                    </Content>
                )}

            </ContainerForm>
        </Page>
  );
};
