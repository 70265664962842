import styled from 'styled-components';
import { ReactNode } from 'react';
import { GoBackTo } from '../common/GoBackTo';
import { AtdnText, Colors, spacing } from '@ataden/design-system';

type GoBackProps = {
  path: string;
  label: string;
};

type PageLayoutProps = {
  goBack?: GoBackProps;

  children: ReactNode;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 52px 20px 20px 20px;

    width: 100%;
    height: 100%;
`;


export const PageLayout = ({ goBack, children }: PageLayoutProps) => {

  return (
        <Container>
            {goBack && <GoBackTo path={goBack.path} label={goBack.label}/>}
            {children}
        </Container>
  );
};

export const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 ${spacing(9)} 0;
    gap: ${spacing(5)};
`;


export const PageDescription = styled(AtdnText)`
    color: ${Colors.Brown700};
    margin: ${spacing(5)} 0 0 0;
`;
