import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AtdnButton, AtdnInput, AtdnModal, AtdnText, Colors, Loader } from '@ataden/design-system';
import { ExplorationItem } from '../domain/FileExploration';
import { useExploreDeleteItems } from '../repository/ExploreFileRepository.hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { BucketDetails } from '../domain/Bucket';

type DeleteItemsModalProps = {
  bucket: BucketDetails;
  items: ExplorationItem[];

  onClosed: () => void;
  onDeleted: () => void;
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 90%;
    text-transform: uppercase;
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled(AtdnText)`
    color: ${Colors.Brown700};
    margin-bottom: 16px;
    word-break: break-word;
`;

const Warning = styled(AtdnText)`
    padding: 16px;
    background-color: ${Colors.Yellow100};
    color: ${Colors.Yellow900};
    border-radius: 8px;
    margin-bottom: 16px;
`;

const Danger = styled(AtdnText)`
    padding: 16px;
    background-color: ${Colors.Red100};
    color: ${Colors.Red900};
    border-radius: 8px;
    margin-bottom: 16px;
`;

const DeleteInputWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

`;

const DeleteInput = styled(AtdnInput)`
    margin-bottom: 48px;
`;

export const DeleteItemsModal = ({ bucket, items, onClosed, onDeleted }: DeleteItemsModalProps) => {
  const intl = useIntl();

  const exploreDeleteItems = useExploreDeleteItems();

  const [confirmValue, updateConfirmValue] = useState<string>('');
  const [isDeleting, updateDeleting] = useState<boolean>(false);

  const onDeleteItems = () => {
    const folderIds = items.filter((item) => item.type === 'FOLDER')
      .map((item) => item.id);

    const fileIds = items.filter((item) => item.type === 'FILE')
      .map((item) => item.id);

    updateDeleting(true);
    exploreDeleteItems(bucket.id, folderIds, fileIds)
      .then(() => {
        onDeleted();
      })
      .catch(() => {
        // TODO: manage error
      });

  };

  const [title, updateTitle] = useState<string>('');
  const [description, updateDescription] = useState<string>('');
  const [warning, updateWarning] = useState<string>('');

  useEffect(() => {
    if (items.length > 1) {
      updateTitle(intl.formatMessage({ id: 'bucket.files.delete.modal.items.title' }));
      updateDescription(intl.formatMessage({ id: 'bucket.files.delete.modal.items.description' }, { count: items.length }));

      if (bucket.retentionDay > 0) {
        updateWarning(intl.formatMessage({ id: 'bucket.files.delete.modal.items.warning' }, { retentionDays: bucket.retentionDay }));
      } else {
        updateWarning(intl.formatMessage({ id: 'bucket.files.delete.modal.items.danger' }));
      }
      return;
    }

    const item = items[0];
    if (item.type === 'FOLDER') {
      updateTitle(intl.formatMessage({ id: 'bucket.files.delete.modal.folder.title' }));
      updateDescription(intl.formatMessage({ id: 'bucket.files.delete.modal.folder.description' }, { name: item.name }));

      if (bucket.retentionDay > 0) {
        updateWarning(intl.formatMessage({ id: 'bucket.files.delete.modal.folder.warning' }, { retentionDays: bucket.retentionDay }));
      } else {
        updateWarning(intl.formatMessage({ id: 'bucket.files.delete.modal.folder.danger' }));
      }

      return;
    }

    updateTitle(intl.formatMessage({ id: 'bucket.files.delete.modal.file.title' }));
    updateDescription(intl.formatMessage({ id: 'bucket.files.delete.modal.file.description' }, { name: item.name }));

    if (bucket.retentionDay > 0) {
      updateWarning(intl.formatMessage({ id: 'bucket.files.delete.modal.file.warning' }, { retentionDays: bucket.retentionDay }));
    } else {
      updateWarning(intl.formatMessage({ id: 'bucket.files.delete.modal.file.danger' }));
    }

  }, [items]);

  const isInputMatchingKeywork = () => {
    const input = confirmValue.toLowerCase().trim();
    const keywork = intl.formatMessage({ id: 'bucket.files.delete.modal.keyword' }).toLowerCase().trim();

    return input === keywork;
  };

  return <AtdnModal onCancel={onClosed}>
        <Content>
            <Title>{title}</Title>
            <Label>{description}</Label>

            {bucket.retentionDay > 0
              ? <Warning>{warning}</Warning>
              : <Danger>{warning}</Danger>
            }
            <DeleteInput label={intl.formatMessage({ id: 'bucket.files.delete.modal.label' })} value={confirmValue}
                         onChange={(event) => updateConfirmValue(event.target.value)} required/>

            <DeleteInputWrapper>
                {isDeleting ? (
                    <AtdnButton type='Danger' onClick={() => {
                    }} disabled>
                        <FormattedMessage id="bucket.files.delete.modal.progress"/>
                        <Loader scale={1}/></AtdnButton>
                ) : (
                    <AtdnButton type='Danger' onClick={onDeleteItems}
                                disabled={!isInputMatchingKeywork()}>
                        <FormattedMessage id="bucket.files.delete.modal.confirm"/>
                    </AtdnButton>
                )}
            </DeleteInputWrapper>
        </Content>
    </AtdnModal>;
};
