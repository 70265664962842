import { BucketDetails } from '../../domain/Bucket';
import { AtdnButton, AtdnInput, AtdnText, Loader, pushToast, spacing } from '@ataden/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useUpdateBucket } from '../../repository/BucketRepository.hook';
import { FormattedMessage, useIntl } from 'react-intl';

type UpdateBucketNameOptionProps = {
  bucket: BucketDetails;
  onBucketUpdated: (bucket: BucketDetails) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${spacing(8)} 0;
`;

const Title = styled(AtdnText)`
    margin-bottom: ${spacing(3)};
`;

const Description = styled(AtdnText)`
    margin-bottom: ${spacing(3)};
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const ConfirmLine = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

const Input = styled(AtdnInput)`
    width: 100%;
    margin-bottom: ${spacing(3)};
`;

export const UpdateBucketNameOption = ({ bucket, onBucketUpdated }: UpdateBucketNameOptionProps) => {

  const intl = useIntl();
  const updateBucket = useUpdateBucket();

  const [value, updateValue] = useState<string>(bucket.name);
  const [isProgressing, updateProgression] = useState<boolean>(false);

  const onConfirm = () => {
    const updatedName = value.trim();

    updateProgression(true);
    updateBucket(bucket.id, {
      updatedName: updatedName,
    })
      .then(() => {
        onBucketUpdated({ ...bucket, name: updatedName });

        pushToast({
          title: intl.formatMessage({ id: 'bucket.settings.name.toast.success.title' }),
          description: intl.formatMessage({ id: 'bucket.settings.name.toast.success.description' }),
          type: 'SUCCESS',
          durationInMs: 15000,
        });
      })
      .catch(() => {
        // TODO: handle error
      })
      .finally(() => {
        updateProgression(false);
      });

  };

  const onValueChanged = value.trim() !== bucket.name;

  return (
        <Container>
            <Title type="important">
                <FormattedMessage id="bucket.settings.name.title"/>
            </Title>
            <Description>
                <FormattedMessage id="bucket.settings.name.description"/>
            </Description>
            <InputContainer>
                <Input label={intl.formatMessage({ id: 'bucket.settings.name.input.label' })} value={value}
                       onChange={(evt) => updateValue(evt.target.value)}/>
                <ConfirmLine>
                    {onValueChanged && !isProgressing &&
                        <AtdnButton size="normal" type="Success" onClick={onConfirm}>
                            <FormattedMessage id="bucket.settings.name.confirm.btn"/>
                        </AtdnButton>}

                    {isProgressing &&
                        <AtdnButton size="normal" type="Success" disabled>
                            <FormattedMessage id="bucket.settings.name.progress"/>
                            <Loader scale={1}/>
                        </AtdnButton>}
                </ConfirmLine>
            </InputContainer>
        </Container>

  );
};
