import React, { useCallback, useEffect, useState } from 'react';
import { ExplorationItem } from '../domain/FileExploration';
import { Page } from '../../common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useExploreFiles } from '../repository/ExploreFileRepository.hook';
import { getRouterBucketPath } from '../../AuthenticatedRouter';
import { computePageCount } from './BucketFileExplorer.utils';
import { BucketFileDisplayer } from './BucketFileDisplayer';
import { BucketToolBar } from './BucketToolBar';
import { BucketDetails } from '../domain/Bucket';

type BucketFileExplorerProps = {
  projectId: string;
  bucket: BucketDetails;
};

const BUCKET_PAGE_SIZE: number = 20;


export const BucketFileExplorer = ({ projectId, bucket }: BucketFileExplorerProps) => {
  const navigate = useNavigate();
  const exploreFiles = useExploreFiles();

  const [searchParams] = useSearchParams();
  const [explorerItems, updateExplorerItems] = useState<ExplorationItem[]>([]);
  const [selectedItems, updateSelectedItems] = useState<ExplorationItem[]>([]);
  const [isLoading, updateLoading] = useState<boolean>(false);

  const [pageCount, updatePageCount] = useState<number>(1);
  const pageNumber = +(searchParams.get('page') || '1');
  const pagePath = searchParams.get('path') || '/';

  const onPageChange = (expectedPageNumber: number, expectedPagePath: string) => {
    navigate(getRouterBucketPath(projectId, bucket.id, expectedPageNumber, expectedPagePath));
  };

  const onFileResult = useCallback((result: Page<ExplorationItem>) => {
    const computedPageCount = computePageCount(result.totalElements, BUCKET_PAGE_SIZE);
    updatePageCount(computedPageCount);
    updateSelectedItems([]);

    if (computedPageCount < pageNumber && computedPageCount > 0) {
      onPageChange(computedPageCount, pagePath);
    } else {
      updateExplorerItems(result.items);
    }

  }, [pageNumber]);

  const loadContent = useCallback(() => {
    updateLoading(true);

    exploreFiles(bucket.id, {
      pageNumber: pageNumber - 1,
      pageSize: BUCKET_PAGE_SIZE,
      path: pagePath,
      showDeleted: bucket.showDeletion,
    }).then(onFileResult)
      .catch(() => {
        // TODO: to manage
      })
      .finally(() => {
        updateLoading(false);
      });
  }, [bucket.id, pageNumber, pagePath, onFileResult]);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  const onItemSelected = (items: string[]) => {
    const result = items.map((itemId) => explorerItems.find((item) => item.id === itemId))
      .filter((item) => item !== undefined)
      .map((item) => item!);

    updateSelectedItems(result);
  };

  const onContentUpdated = () => {
    updateSelectedItems([]);
    loadContent();
  };

  const onRecovered = () => {
    loadContent();
  };

  return (
        <>
            <BucketToolBar bucket={bucket} pagePath={pagePath} selectedItems={selectedItems}
                           onFileCreated={onContentUpdated} onFolderCreated={onContentUpdated}
                           onItemDeleted={onContentUpdated}/>

            <BucketFileDisplayer
                bucketId={bucket.id}
                pageNumber={pageNumber}
                pageCount={pageCount}
                pagePath={pagePath}
                explorerItems={explorerItems}
                isLoading={isLoading}
                onPageChange={onPageChange}
                onItemSelectionChanged={onItemSelected}
                onRecovered={onRecovered}/>
        </>
  );

};
