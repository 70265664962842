import styled from 'styled-components';
import React from 'react';
import { AtdnButton, AtdnText, Colors, Loader } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../AuthenticatedRouter';

type LoginComponentProps = {
  isLoading: boolean;
  isAuthenticated: boolean;

  onLoginRequested: () => void;
};

const LoginDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 36px 0;
  justify-content: center;
  width: 100%;
`;

const Title = styled(AtdnText)`
  text-align: left;

  margin-bottom: 24px;
`;

const Description = styled(AtdnText)`
  text-align: left;
`;

const ButtonWithMargin = styled(AtdnButton)`
  margin-bottom: 24px;
`;

export const LoginComponent = ({ isLoading, isAuthenticated, onLoginRequested }: LoginComponentProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    return <Loader/>;
  }

  const onGoToDashboard = () => {
    navigate(RoutePath.dashboard);
  };

  if (isAuthenticated) {
    return (
            <>
                <LoginDescription>
                    <Title scale={8} type="important" color={Colors.Brown700}>
                        <FormattedMessage id="auth.login.already-connected.title"/>
                    </Title>
                    <Description scale={4} type="normal" color={Colors.Brown700}>
                        <FormattedMessage id="auth.login.already-connected.description"/>
                    </Description>
                </LoginDescription>

                <ButtonWithMargin size='big' type="Success" onClick={onGoToDashboard}>
                    <FormattedMessage id="auth.login.already-connected.dashboard"/>
                </ButtonWithMargin>

                <AtdnButton size='big' type="Primary" onClick={onLoginRequested}>
                    <FormattedMessage id="auth.login.already-connected.cta"/>
                </AtdnButton>
            </>
    );
  }

  return (
        <>
            <LoginDescription>
                <Title scale={8} type="important" color={Colors.Brown700}>
                    <FormattedMessage id="auth.login.title"/>
                </Title>
                <Description scale={5} type="normal" color={Colors.Brown700}>
                    <FormattedMessage id="auth.login.connect.description"/>
                </Description>
            </LoginDescription>
            <AtdnButton size='big' type="Success" onClick={onLoginRequested}>
                <FormattedMessage id="auth.login.cta"/>
            </AtdnButton>
        </>
  );

};
