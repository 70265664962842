import React, { createContext, ReactNode, useMemo } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

type PaymentProviderProps = {
  children: ReactNode;
};

export interface PaymentInterface {
  stripePromise: Promise<Stripe | null>;
}

export const PaymentContext = createContext<Partial<PaymentInterface>>({});

export const PaymentContextProvider: React.FC<PaymentInterface> = ({ children, ...config }) => {
  return <PaymentContext.Provider value={config}>{children}</PaymentContext.Provider>;
};

export const PaymentProvider = ({ children }: PaymentProviderProps) => {

  const stripePromise = useMemo(() => {
    return loadStripe(process.env.REACT_APP__STRIPE_API!);

  }, []);

  return <PaymentContextProvider stripePromise={stripePromise}>{children}</PaymentContextProvider>;
};
