import styled from 'styled-components';
import { AtdnText, Colors } from '@ataden/design-system';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-weight: 400;
    align-items: center;

    &:hover {
        font-weight: 600;
    }
`;

const ItemLabel = styled(AtdnText)`
    color: ${Colors.Brown700};

    &:hover {
        font-weight: 600;
    }
`;

type MenuItemProps = {
  title: string;

  onClick: () => void;
};

export const BannerMenuItem = ({ title, onClick }: MenuItemProps) => {

  return (
        <Container onClick={onClick}>
            <ItemLabel scale={4}>{title}</ItemLabel>
        </Container>
  );
};
