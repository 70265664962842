import { Bucket } from '../../domain/Bucket';
import styled from 'styled-components';
import { AtdnCopyButton, AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRouterKeysPath, useGetProjectIdFromPath } from '../../../AuthenticatedRouter';

type S3SectionProps = {
  bucket: Bucket;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(AtdnText)`
  margin-bottom: ${spacing(5)};
`;

const Description = styled(AtdnText)`
  margin-bottom: ${spacing(3)};
`;

const KeyHyperText = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const UrlRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: ${spacing(5)};
`;

const Url = styled(AtdnText)`
  border: 1px solid ${Colors.Brown700};
  border-radius: 4px;
  padding: 4px;
  background-color: ${Colors.Gray000};
  margin-bottom: ${spacing(5)};
`;

const CopyKeyButton = styled(AtdnCopyButton)`
  margin-left: 8px;
`;

export const S3Section = ({ bucket }: S3SectionProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const projectId = useGetProjectIdFromPath();

  const bucketUrl = `${process.env.REACT_APP__FILE_URL}/${bucket.id}`;

  const onKeyPage = () => {
    navigate(getRouterKeysPath(projectId));
  };

  return (
        <Container>
            <Title type="important">
                <FormattedMessage id="bucket.api.s3.title"/>
            </Title>
            <Description>
                <FormattedMessage id="bucket.api.s3.description"/>
            </Description>
            <UrlRow>
                <Url>{bucketUrl}</Url>
                <CopyKeyButton textToCopy={bucketUrl}
                               tooltipToCopy={intl.formatMessage({ id: 'bucket.api.s3.url.copy' })}
                               tooltipCopied={intl.formatMessage({ id: 'bucket.api.s3.url.copied' })}/>
            </UrlRow>
            <KeyHyperText onClick={onKeyPage}>
                <AtdnText>
                    <FormattedMessage id="bucket.api.s3.keys"/>
                </AtdnText>
            </KeyHyperText>
        </Container>
  );
};