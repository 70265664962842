import { ReactElement, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

export type TranslationBundle = {
  french: any;
  english: any;
};

export type TranslationConfigProviderProps = {
  children: ReactElement;
  translations: TranslationBundle;
};

enum Languages {
  EN,
  FR,
}

const getLocale = (language: Languages, translations: TranslationBundle) => {
  if (language === Languages.FR) {
    return translations.french;
  }
  return translations.english;
};

const localeToLanguage = (locale: string): Languages | undefined => {

  if (locale.startsWith('fr')) {
    return Languages.FR;
  }

  if (locale.startsWith('en')) {
    return Languages.EN;
  }
};

const getLanguage = (): Languages => {
  if (!window || !window.navigator || !window.navigator.languages) {
    return Languages.EN;
  }

  const languages = window.navigator.languages;
  if (languages.length === 0) {
    return Languages.EN;
  }

  for (let lang of languages) {
    const language = localeToLanguage(lang);
    if (language !== undefined) {
      return language;
    }
  }

  return Languages.EN;
};

export const TranslationConfigProvider = ({ translations, children }: TranslationConfigProviderProps) => {
  const language = getLanguage();
  const messages = useMemo(() => {
    return getLocale(language, translations);
  }, [language]);

  return (
        <IntlProvider messages={messages} locale={Languages[language]} defaultLocale="EN">
            <>
                {children}
            </>
        </IntlProvider>
  );
};
