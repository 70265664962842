import { AtdnCheckbox, AtdnCheckBoxState, AtdnText, Colors } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import { ExplorerItemContainer } from './ExplorerItem.style';
import { ExplorerItemSelectMode } from './ExplorerItem';

type ExplorerItemHeaderProps = {
  selectMode: ExplorerItemSelectMode;
  onSelectAll: () => void;
  onUnselectAll: () => void;
};


export const ExplorerItemHeader = ({ selectMode, onSelectAll, onUnselectAll }: ExplorerItemHeaderProps) => {

  const onSelectionCheckBox = (value: AtdnCheckBoxState) => {
    if (value === 'CHECKED') {
      onSelectAll();
      return;
    }
    onUnselectAll();
  };

  const computeState = (mode: ExplorerItemSelectMode): AtdnCheckBoxState => {
    switch (mode) {
      case 'ALL':
        return 'CHECKED';
      case 'NONE':
        return 'UNCHECKED';
      case 'CUSTOM':
        return 'UNDEFINED';
    }
  };

  const checkBoxState = computeState(selectMode);

  return (
        <ExplorerItemContainer>
            <AtdnCheckbox state={checkBoxState} onChange={onSelectionCheckBox}/>
            <AtdnText type="important" color={Colors.Brown700}>
                <FormattedMessage id="bucket.file.list.header.name"/>
            </AtdnText>
            <AtdnText type="important" color={Colors.Brown700}>
                <FormattedMessage id="bucket.file.list.header.size"/>
            </AtdnText>
            <AtdnText type="important" color={Colors.Brown700}>
                <FormattedMessage id="bucket.file.list.header.createdDate"/>
            </AtdnText>
            <div/>
        </ExplorerItemContainer>
  );
};
