import { createContext, ReactElement, useMemo } from 'react';
import { ActionCreatorWithPayload, configureStore } from '@reduxjs/toolkit';
import { BusinessMember } from './domain/BusinessMember';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'redux';
import { businessStoreSlice } from './BusinessStore';
import { BusinessInfo } from './domain/BusinessInfo';

type BusinessContextProviderProps = {
  children: ReactElement;
};

type BusinessConfig = {
  store: Store;

  initBusinessMemberAction: ActionCreatorWithPayload<BusinessMember[]>;
  initCurrentBusinessAction: ActionCreatorWithPayload<BusinessInfo | undefined>;
} & BusinessContextProviderProps;


export const BusinessContext = createContext<Partial<BusinessConfig>>({});

const Provider: React.FC<BusinessConfig> = (config: BusinessConfig) => {
  return <BusinessContext.Provider value={config}>{config.children}</BusinessContext.Provider>;
};

export const BusinessContextProvider = ({ children }: BusinessContextProviderProps) => {
  const store: Store = useMemo(() => configureStore({
    reducer: businessStoreSlice.reducer,
  }), []);

  const {
    initBusinessMember,
    initCurrentBusiness,
  } = businessStoreSlice.actions;

  return (
        <Provider
            store={store}
            initBusinessMemberAction={initBusinessMember}
            initCurrentBusinessAction={initCurrentBusiness}
        >
            {children}
        </Provider>
  );
};
