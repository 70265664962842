import styled from 'styled-components';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { buildPageIndexes } from './PageSelectorIndexBuilder';

type PageSelectorProps = {
  pageCount: number;
  currentPage: number;
  onPageSelection: (pageNumber: number) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: ${spacing(5)};
    width: 100%;
    justify-content: center;
`;

const Item = styled(AtdnText)`
    cursor: pointer;
`;

const SelectedItem = styled(Item)`
    text-decoration: underline;
`;

export const PageSelector = ({ pageCount, currentPage, onPageSelection }: PageSelectorProps) => {
  const items = buildPageIndexes(pageCount, currentPage);

  const onItemClick = (pageNumber: number) => {
    onPageSelection(pageNumber);
  };

  if (pageCount <= 1) {
    return <></>;
  }

  return (
        <Container>
            {items.map((item, idx) => {
              if (!item.selectable) {
                return (<div key={'' + idx}>...</div>);
              }

              if (item.value === currentPage) {
                return (
                        <SelectedItem key={item.value} type="important" color={Colors.Brown700}
                                      scale={5}>{item.value}</SelectedItem>);
              }
              return (<div key={item.value} onClick={() => onItemClick(item.value)}><Item color={Colors.Brown700}
                                                                                            scale={5}>{item.value}</Item>
                </div>);
            })}
        </Container>
  );
};
