import React, { useState } from 'react';
import styled from 'styled-components';
import { AtdnButton, AtdnInput, AtdnModal, AtdnText, Colors, Loader } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeleteBucket } from '../../repository/BucketRepository.hook';
import { Bucket } from '../../domain/Bucket';

type DeleteBucketModalProps = {
  bucket: Bucket;

  onClosed: () => void;
  onDeleted: () => void;
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 90%;
    text-transform: uppercase;
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled(AtdnText)`
    color: ${Colors.Brown700};
    margin-bottom: 16px;
    word-break: break-word;
`;

const Warning = styled(AtdnText)`
    padding: 16px;
    background-color: ${Colors.Yellow100};
    color: ${Colors.Yellow900};
    border-radius: 8px;
    margin-bottom: 16px;
`;

const DeleteInputWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

`;

const DeleteInput = styled(AtdnInput)`
    margin-bottom: 48px;
`;

export const DeleteBucketModal = ({ bucket, onClosed, onDeleted }: DeleteBucketModalProps) => {
  const intl = useIntl();

  const deleteBucket = useDeleteBucket();

  const [confirmValue, updateConfirmValue] = useState<string>('');
  const [isDeleting, updateDeleting] = useState<boolean>(false);

  const onDeleteItems = () => {

    updateDeleting(true);
    deleteBucket(bucket.id)
      .then(() => {
        onDeleted();
      })
      .catch(() => {
        // TODO: manage error
      });

  };

  const isInputMatchingKeywork = () => {
    const input = confirmValue.toLowerCase().trim();
    const keywork = intl.formatMessage({ id: 'bucket.settings.delete.modal.keyword' }).toLowerCase().trim();

    return input === keywork;
  };

  return <AtdnModal onCancel={onClosed}>
        <Content>
            <Title>
                <FormattedMessage id="bucket.settings.delete.modal.title"/>
            </Title>
            <Label>
                <FormattedMessage id="bucket.settings.delete.modal.description" values={{ name: bucket.name }}/>
            </Label>
            <Warning>
                <FormattedMessage id="bucket.settings.delete.modal.warning"/>
            </Warning>
            <DeleteInput label={intl.formatMessage({ id: 'bucket.settings.delete.modal.label' })} value={confirmValue}
                         onChange={(event) => updateConfirmValue(event.target.value)} required/>

            <DeleteInputWrapper>
                {isDeleting ? (
                    <AtdnButton type='Danger' onClick={() => {
                    }} disabled>
                        <FormattedMessage id="bucket.settings.delete.modal.progress"/>
                        <Loader scale={1}/></AtdnButton>
                ) : (
                    <AtdnButton type='Danger' onClick={onDeleteItems}
                                disabled={!isInputMatchingKeywork()}>
                        <FormattedMessage id="bucket.settings.delete.modal.confirm"/>
                    </AtdnButton>
                )}
            </DeleteInputWrapper>
        </Content>
    </AtdnModal>;
};
